import { mergeStyleSets } from "@fluentui/react";
import { getThemeShapeOption, getThemeSizeOption, pxToRem } from "Application/Helpers";
import { PropsWithChildren } from "react";

import { theme } from "../../AppTheme";
import { ComboBoxBase, IComboBoxBaseProps } from "../../../Components/StyleFrameworks/FluentUI/Primitives/Active/ComboBoxBase";
import { observer } from "mobx-react-lite";

export const ThemedComboBox = observer((props: PropsWithChildren<IComboBoxBaseProps>) => {
    const size = getThemeSizeOption(props.size);
    const shape = getThemeShapeOption(props.shape);

    // Note. When merging styles, do this after the props spreader, otherwise props.styles will overwrite
    // base styles instead of being merged.
    return (
        <ComboBoxBase
            {...props}
            styles={mergeStyleSets(
                // The first style set concerns the border of the combo box. Setting the
                // border color on hover and focus states is messy, so I've extracted those
                // style properties here.
                {
                    root: {
                        "::after": {
                            borderRadius: shape,
                            borderColor: theme.palette.field.light,
                        },
                        ":hover:hover::after:not(:focus)": {
                            borderColor: theme.palette.field.main,
                        },
                        button: {
                            borderRadius: shape,
                            right: 0,
                            width: props.shape === "round" ? size : "auto",
                        },
                    },
                    rootFocused: {
                        "::after": {
                            borderColor: theme.palette.common.focus,
                        },
                    },
                    rootHovered: {
                        "::after": {
                            borderColor: theme.palette.field.main,
                        },
                    },
                    rootPressed: {
                        "::after": {
                            borderColor: theme.palette.common.focus,
                        },
                    },
                    rootError: {
                        "::after": {
                            borderColor: theme.palette.error.light,
                        },
                        ":hover::after": {
                            borderColor: theme.palette.field.main,
                        },
                    },
                },
                {
                    root: {
                        fontFamily: theme.fontStyles.field.fontFamily ?? theme.defaultFontStyle.fontFamily,
                        fontSize: theme.fontStyles.field.fontSize ?? theme.defaultFontStyle.fontSize,
                        fontWeight: theme.fontStyles.field.fontWeight ?? theme.defaultFontStyle.fontWeight,
                        height: size,
                        letterSpacing: theme.fontStyles.field.letterSpacing ?? theme.defaultFontStyle.letterSpacing,
                        lineHeight: theme.fontStyles.field.lineHeight ?? theme.defaultFontStyle.lineHeight,
                        textTransform: theme.fontStyles.field.textTransform ?? theme.defaultFontStyle.textTransform,
                        paddingLeft: pxToRem(10),
                    },
                    errorMessage: {
                        color: theme.palette.common.error,
                        fontFamily: theme.fontStyles.error.fontFamily ?? theme.defaultFontStyle.fontFamily,
                        fontSize: theme.fontStyles.error.fontSize ?? theme.defaultFontStyle.fontSize,
                        fontWeight: theme.fontStyles.error.fontWeight ?? theme.defaultFontStyle.fontWeight,
                        letterSpacing: theme.fontStyles.error.letterSpacing ?? theme.defaultFontStyle.letterSpacing,
                        lineHeight: theme.fontStyles.error.lineHeight ?? theme.defaultFontStyle.lineHeight,
                        textTransform: theme.fontStyles.error.textTransform ?? theme.defaultFontStyle.textTransform,
                    },
                    input: {
                        color: theme.palette.field.text,
                    },
                    label: {
                        color: theme.palette.primary.text,
                        fontFamily: theme.fontStyles.label.fontFamily ?? theme.defaultFontStyle.fontFamily,
                        fontSize: theme.fontStyles.label.fontSize ?? theme.defaultFontStyle.fontSize,
                        fontWeight: 400,
                        letterSpacing: theme.fontStyles.label.letterSpacing ?? theme.defaultFontStyle.letterSpacing,
                        lineHeight: theme.fontStyles.label.lineHeight ?? theme.defaultFontStyle.lineHeight,
                        textTransform: theme.fontStyles.label.textTransform ?? theme.defaultFontStyle.textTransform,
                        marginTop: "-8px",
                        paddingBottom: "6px",
                    },
                    optionsContainer: {
                        fontFamily: theme.fontStyles.field.fontFamily ?? theme.defaultFontStyle.fontFamily,
                        fontSize: theme.fontStyles.field.fontSize ?? theme.defaultFontStyle.fontSize,
                        fontWeight: theme.fontStyles.field.fontWeight ?? theme.defaultFontStyle.fontWeight,
                        letterSpacing: theme.fontStyles.field.letterSpacing ?? theme.defaultFontStyle.letterSpacing,
                        lineHeight: theme.fontStyles.field.lineHeight ?? theme.defaultFontStyle.lineHeight,
                        textTransform: theme.fontStyles.field.textTransform ?? theme.defaultFontStyle.textTransform,
                    },
                },
                props.styles,
            )}
            optionStyles={mergeStyleSets(
                {
                    optionText: {
                        fontSize: theme.fontStyles.field.fontSize ?? theme.defaultFontStyle.fontSize,
                    },
                    optionTextWrapper: {
                        height: size,
                    },
                },
                props.optionStyles,
            )}
        />
    );
});

// #region Directly-styled text

/**
 * WARNING - If your styled components needs to change style because of change in the state of the
 * model (via the props), wrap the component in a mobx observer.
 */
export const ThemedDefaultComboBox = observer((props: PropsWithChildren<IComboBoxBaseProps>) => {
    // Note. When merging styles, do this after the props spreader, otherwise props.styles will overwrite
    // base styles instead of being merged.
    return (
        <ComboBoxBase
            {...props}
            styles={mergeStyleSets(
                // The first style set concerns the border of the combo box. Setting the
                // border color on hover and focus states is messy, so I've extracted those
                // style properties here.
                {
                    root: {
                        "::after": {
                            borderColor: theme.palette.field.light,
                        },
                        ":hover:hover::after:not(:focus)": {
                            borderColor: theme.palette.field.main,
                        },
                        button: {
                            right: 0,
                        },
                    },
                    rootFocused: {
                        "::after": {
                            borderColor: theme.palette.common.focus,
                        },
                    },
                    rootHovered: {
                        "::after": {
                            borderColor: theme.palette.field.main,
                        },
                    },
                    rootPressed: {
                        "::after": {
                            borderColor: theme.palette.common.focus,
                        },
                    },
                    rootError: {
                        "::after": {
                            borderColor: theme.palette.error.light,
                        },
                        ":hover::after": {
                            borderColor: theme.palette.field.main,
                        },
                    },
                },
                {
                    root: {
                        fontFamily: theme.fontStyles.field.fontFamily ?? theme.defaultFontStyle.fontFamily,
                        fontSize: theme.fontStyles.field.fontSize ?? theme.defaultFontStyle.fontSize,
                        fontWeight: theme.fontStyles.field.fontWeight ?? theme.defaultFontStyle.fontWeight,
                        height: theme.sizes.larger,
                        letterSpacing: theme.fontStyles.field.letterSpacing ?? theme.defaultFontStyle.letterSpacing,
                        lineHeight: theme.fontStyles.field.lineHeight ?? theme.defaultFontStyle.lineHeight,
                        textTransform: theme.fontStyles.field.textTransform ?? theme.defaultFontStyle.textTransform,
                        paddingLeft: pxToRem(10),
                    },
                    errorMessage: {
                        color: theme.palette.common.error,
                        fontFamily: theme.fontStyles.error.fontFamily ?? theme.defaultFontStyle.fontFamily,
                        fontSize: theme.fontStyles.error.fontSize ?? theme.defaultFontStyle.fontSize,
                        fontWeight: theme.fontStyles.error.fontWeight ?? theme.defaultFontStyle.fontWeight,
                        letterSpacing: theme.fontStyles.error.letterSpacing ?? theme.defaultFontStyle.letterSpacing,
                        lineHeight: theme.fontStyles.error.lineHeight ?? theme.defaultFontStyle.lineHeight,
                        textTransform: theme.fontStyles.error.textTransform ?? theme.defaultFontStyle.textTransform,
                    },
                    input: {
                        color: theme.palette.field.text,
                    },
                    label: {
                        color: theme.palette.common.default,
                        fontFamily: theme.fontStyles.label.fontFamily ?? theme.defaultFontStyle.fontFamily,
                        fontSize: theme.fontStyles.label.fontSize ?? theme.defaultFontStyle.fontSize,
                        fontWeight: theme.fontStyles.label.fontWeight ?? theme.defaultFontStyle.fontWeight,
                        letterSpacing: theme.fontStyles.label.letterSpacing ?? theme.defaultFontStyle.letterSpacing,
                        lineHeight: theme.fontStyles.label.lineHeight ?? theme.defaultFontStyle.lineHeight,
                        textTransform: theme.fontStyles.label.textTransform ?? theme.defaultFontStyle.textTransform,
                    },
                    optionsContainer: {
                        fontFamily: theme.fontStyles.field.fontFamily ?? theme.defaultFontStyle.fontFamily,
                        fontSize: theme.fontStyles.field.fontSize ?? theme.defaultFontStyle.fontSize,
                        fontWeight: theme.fontStyles.field.fontWeight ?? theme.defaultFontStyle.fontWeight,
                        letterSpacing: theme.fontStyles.field.letterSpacing ?? theme.defaultFontStyle.letterSpacing,
                        lineHeight: theme.fontStyles.field.lineHeight ?? theme.defaultFontStyle.lineHeight,
                        textTransform: theme.fontStyles.field.textTransform ?? theme.defaultFontStyle.textTransform,
                    },
                },
                props.styles,
            )}
            optionStyles={mergeStyleSets(
                {
                    root: {
                        height: theme.sizes.larger,
                    },
                    optionText: {
                        fontFamily: theme.fontStyles.field.fontFamily ?? theme.defaultFontStyle.fontFamily,
                        fontSize: theme.fontStyles.field.fontSize ?? theme.defaultFontStyle.fontSize,
                    },
                },
                props.optionStyles,
            )}
        />
    );
});

// #endregion

import React, { useState } from "react";
import { IStyle, mergeStyleSets } from "@fluentui/react";
import { observer } from "mobx-react-lite";

import { HeaderViewModel } from "./HeaderViewModel";
import { HeaderLink } from "./HeaderLink";
import { HeaderLogoOptionBase } from "./HeaderLogoOptions";
import { Sidebar } from "./Sidebar";
import useWindowDimensions from "../../Application/Hooks/useWindowDimensions";
import { theme } from "../../Styles";
import { Box, Label } from "../../Components";
import { AppUrls } from "../../AppUrls";
import { DashboardSVG } from "../../Assets/Icons/DashboardSVG";
import { YourHomeSVG } from "../../Assets/SVGs/YourHomeSVG";
import { BuildProgressSVG } from "../../Assets/SVGs/BuildProgressSVG";
import { SalesProgressSVG } from "../../Assets/SVGs/SalesProgressSVG";
import { DocumentsSVG } from "../../Assets/SVGs/DocumentsSVG";
import { ChoicesSVG } from "../../Assets/SVGs/ChoicesSVG";
import { CustomerCareSVG } from "../../Assets/SVGs/CustomerCare.svg";
import { LogoutSVG } from "../../Assets/Icons/LogoutSVG";
import { HorizontalBlock } from "Components/HorizontalBlock";
import image1 from "Assets/panel1.png";
import image2 from "Assets/panelM2.png";
import image3 from "Assets/panelM3.png";
import image4 from "Assets/panelM4.png";
import image5 from "Assets/panel5.png";
import image6 from "Assets/panelM6.png";
import house1Desktop from "Assets/1stPanel.png";
import house1Mobile from "Assets/mainImage@2x.png";
import dashboard from "Assets/dashboard.jpg";
import { MenuBlock } from "Components/MenuBlock";
import { AccountStore } from "../../Stores/Domain";
import { container } from "tsyringe";
import LogoutView from "Views/Logout/LogoutView";
export interface IHeaderStyles {
    root: IStyle;
    content: IStyle;
    logoOption: IStyle;
    options: IStyle;
    option: IStyle;
    optionActive: IStyle;
}

export interface IHeaderProps {
    /**
     * An optional class name for use with the header.
     */
    className?: string;
    /**
     * Styling of the header.
     */
    styles?: IHeaderStyles;
}

export const HeaderBase: React.FC<IHeaderProps> = observer((props) => {
    // #region Code behind
    const [viewModel] = useState(() => new HeaderViewModel());
    const { height, width } = useWindowDimensions();
    const accountStore = container.resolve(AccountStore);
    const styles = mergeStyleSets(props.styles);

    const isSalesAdmin = () => accountStore.isInRole("sales admin");
    const isAdmin = () => accountStore.isInRole("admin");
    const isPortal = accountStore.CoinsDevelopmentId && accountStore.CoinsPlotId;

    const menuLinksForDesktop = () => {
        return (
            <>
                <div className={styles.options}>
                    {isPortal && (
                        <HeaderLink
                            className={styles.option}
                            selectedClassName={styles.optionActive}
                            command={viewModel.navigateToHomeCommand}
                            displayName={viewModel.dashBoardViewModel.getValue("displayName")}
                            isSelected={viewModel.dashBoardViewModel.getValue("isActive")}
                        />
                    )}
                    {isPortal && (
                        <HeaderLink
                            className={styles.option}
                            selectedClassName={styles.optionActive}
                            command={viewModel.navigateToYourHomeCommand}
                            displayName={viewModel.yourHomeViewModel.getValue("displayName")}
                            isSelected={viewModel.yourHomeViewModel.getValue("isActive")}
                        />
                    )}
                    {isPortal && (
                        <HeaderLink
                            className={styles.option}
                            selectedClassName={styles.optionActive}
                            command={viewModel.navigateToBuildProgressCommand}
                            displayName={viewModel.yourBuildProgressModel.getValue("displayName")}
                            isSelected={viewModel.yourBuildProgressModel.getValue("isActive")}
                        />
                    )}
                    {isPortal && (
                        <HeaderLink
                            className={styles.option}
                            selectedClassName={styles.optionActive}
                            command={viewModel.navigateToSalesProgressCommand}
                            displayName={viewModel.salesProgressModel.getValue("displayName")}
                            isSelected={viewModel.salesProgressModel.getValue("isActive")}
                        />
                    )}
                    {isPortal && (
                        <HeaderLink
                            className={styles.option}
                            selectedClassName={styles.optionActive}
                            command={viewModel.navigateToDocumentsCommand}
                            displayName={viewModel.documentsModel.getValue("displayName")}
                            isSelected={viewModel.documentsModel.getValue("isActive")}
                        />
                    )}
                    {/* <HeaderLink
                    className={styles.option}
                    selectedClassName={styles.optionActive}
                    command={viewModel.navigateToChoicesCommand}
                    displayName={viewModel.choicesModel.getValue("displayName")}
                    isSelected={viewModel.choicesModel.getValue("isActive")}
                /> */}
                    {isPortal && (
                        <HeaderLink
                            className={styles.option}
                            selectedClassName={styles.optionActive}
                            command={viewModel.navigateToCustomerCareCommand}
                            displayName={viewModel.customerCareModel.getValue("displayName")}
                            isSelected={viewModel.customerCareModel.getValue("isActive")}
                        />
                    )}
                    {!isPortal && !isSalesAdmin() && (
                        <HeaderLink
                            className={styles.option}
                            selectedClassName={styles.optionActive}
                            command={viewModel.navigateToAdminCommand}
                            displayName={viewModel.adminViewModel.getValue("displayName")}
                            isSelected={viewModel.adminViewModel.getValue("isActive")}
                        />
                    )}

                    {isSalesAdmin() && (
                        <HeaderLink
                            className={styles.option}
                            selectedClassName={styles.optionActive}
                            command={viewModel.navigateToPortalCommand}
                            displayName={viewModel.adminViewModel.getValue("displayName")}
                            isSelected={viewModel.adminViewModel.getValue("isActive")}
                        />
                    )}
                    {!isPortal && !isSalesAdmin() && (
                        <HeaderLink
                            className={styles.option}
                            selectedClassName={styles.optionActive}
                            command={viewModel.navigateToBuildStageCommand}
                            displayName={viewModel.yourBuildStageModel.getValue("displayName")}
                            isSelected={viewModel.yourBuildStageModel.getValue("isActive")}
                        />
                    )}
                    {!isPortal && !isSalesAdmin() && (
                        <HeaderLink
                            className={styles.option}
                            selectedClassName={styles.optionActive}
                            command={viewModel.navigateToYourPurchaseCommand}
                            displayName={viewModel.yourSalesStageModel.getValue("displayName")}
                            isSelected={viewModel.yourSalesStageModel.getValue("isActive")}
                        />
                    )}

                    {/* {accountStore.isInRole("admin") && (
                    <HeaderLink
                        className={styles.option}
                        selectedClassName={styles.optionActive}
                        command={viewModel.navigateToAdminCommand}
                        displayName={viewModel.adminViewModel.getValue("displayName")}
                        isSelected={viewModel.adminViewModel.getValue("isActive")}
                    />
                )} */}
                    <HeaderLink
                        className={styles.option}
                        selectedClassName={styles.optionActive}
                        command={viewModel.navigateToLoginCommand}
                        displayName={viewModel.loginViewModel.getValue("displayName")}
                        isSelected={viewModel.loginViewModel.getValue("isActive")}
                    />
                    <HeaderLink
                        className={styles.option}
                        selectedClassName={styles.optionActive}
                        command={viewModel.navigateToLogoutCommand}
                        displayName={viewModel.logoutViewModel.getValue("displayName")}
                        isSelected={viewModel.logoutViewModel.getValue("isActive")}
                    />
                </div>
                <div>
                    <LogoutView isShow={viewModel.logout} onClose={() => viewModel.logoutClose.execute()} />
                </div>
            </>
        );
    };

    const menuLinksForMobile = () => {
        return (
            <>
                <Box dc={"1fr"}>
                    {/* <BuildProgressSVG showblankcircle={false} style={{ visibility: "hidden", position: "absolute" }} /> */}
                    {isPortal && (
                        <>
                            <MenuBlock title={"Dashboard"} path={AppUrls.Client.Home} icon={<DashboardSVG />} image={dashboard} />
                            <MenuBlock title={"Your home"} path={AppUrls.Client.YourHome} icon={<YourHomeSVG showblankcircle={false} />} image={house1Mobile} />
                            <MenuBlock title={"Build progress"} path={AppUrls.Client.BuildProgress} icon={<BuildProgressSVG showblankcircle={false} />} image={image2} />
                            <MenuBlock title={"Sales progress"} path={AppUrls.Client.SalesProgress} icon={<SalesProgressSVG />} image={image3} />
                            <MenuBlock title={"Documents"} path={AppUrls.Client.Documents} icon={<DocumentsSVG />} image={image4} />
                            {/* <HorizontalBlock title={"Choices & extras"} path={AppUrls.Client.Choices} icon={<ChoicesSVG />} /> */}
                            <MenuBlock title={"Customer Experience"} path={AppUrls.Client.CustomerCare} icon={<CustomerCareSVG />} image={image6} />
                        </>
                    )}
                    {!isPortal && (
                        <>
                            {!isSalesAdmin() && <MenuBlock title={"Users"} path={AppUrls.Client.Admin.AdminHome} icon={<DashboardSVG />} image={dashboard} />}
                            {isSalesAdmin() && <MenuBlock title={"Users"} path={AppUrls.Client.Admin.PortalHome} icon={<DashboardSVG />} image={dashboard} />}
                            {!isSalesAdmin() && (
                                <MenuBlock title={"Build Stage Admin"} path={AppUrls.Client.BuildStageAdmin} icon={<BuildProgressSVG showblankcircle={false} />} image={image2} />
                            )}
                            {!isSalesAdmin() && <MenuBlock title={"Sales Stage Admin"} path={AppUrls.Client.SalesStageAdmin} icon={<SalesProgressSVG />} image={image3} />}
                        </>
                    )}
                    <MenuBlock title={"Log out"} icon={<LogoutSVG />} image={house1Mobile} onClick={() => viewModel.navigateToLogoutCommand.execute()} />
                </Box>
                <LogoutView isShow={viewModel.logout} onClose={() => viewModel.logoutClose.execute()} />
            </>
        );
    };
    // #endregion Code behind

    return (
        <div id={"headerroot"} className={styles.root}>
            {viewModel.isMobile && (
                <Sidebar width={width as number} color={theme.palette.primary.light} left={true}>
                    {viewModel.isMobile && menuLinksForMobile()}
                </Sidebar>
            )}
            <Box className={styles.content}>
                {isSalesAdmin() && <HeaderLogoOptionBase className={styles.logoOption} command={viewModel.navigateToPortalCommand} />}
                {isAdmin() && !isPortal && <HeaderLogoOptionBase className={styles.logoOption} command={viewModel.navigateToAdminCommand} />}
                {isPortal && <HeaderLogoOptionBase className={styles.logoOption} command={viewModel.navigateToHomeCommand} />}
                <div>{!viewModel.isMobile && menuLinksForDesktop()}</div>
            </Box>
        </div>
    );
});

import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Box, Label, setPageTitle, theme, ThemedDefaultCheckBox, ThemedEditText } from "Application";
import styled from "@emotion/styled";
import { BuildStagesViewModel } from "./BuildStagesViewModel";
import { FileUploaderView } from "Components/FileUploader/FileUploaderView";
import { Editor } from "@tinymce/tinymce-react";

const BuildMsg = styled(Box)`
    .ms-TextField-fieldGroup {
        border: 1px solid #cccbcb;
        input {
            padding: 8px 10px;
            box-sizing: border-box;
        }
        textarea {
            padding: 8px 10px;
            box-sizing: border-box;
            height: 75px !important;
            text-align: justify;
            hyphens: auto;
            overflow-y: auto;
        }
    }
    .ms-TextField-wrapper {
        span {
            font: normal normal bold 12px/15px Lato;
            letter-spacing: -0.12px;
            color: #191919;
        }
    }
    .ms-TextField-errorMessage {
        color: rgb(197, 47, 62);
        font-size: 12px;
    }
`;

const BuildCheckBox = styled(Box)`
    .ms-Checkbox-checkbox {
        border-color: #cccbcb !important;
        .ms-Checkbox-checkmark {
            color: #000 !important;
            font-weight: 900;
            font-size: 20px;
        }
    }
`;

interface IIconContainerProps {
    backgroundImage: string;
}

const IconContainer = styled.div<IIconContainerProps>`
    content: "";
    width: 96px;
    height: 96px;
    border-radius: 50%;
    border: 3px solid ${theme.palette.common.lightBlue};
    background-image: ${(props) => `url(${props.backgroundImage})`};
    background-position: center;
    background-size: cover;
    margin: 0 auto 10px;
`;

const uploadButtonStyles: React.CSSProperties = {
    border: "none",
    background: "none",
    color: theme.palette.common.lightBlue,
    textTransform: "uppercase",
    textDecoration: "underline",
    cursor: "pointer",
};
const RenderHTML = (props: any) => <span dangerouslySetInnerHTML={{ __html: props.HTML }}></span>;

interface Props {
    viewModel: BuildStagesViewModel;
    index?: number;
    total?: number;
}

export const BuildStagesView: React.FC<Props> = observer((props: Props) => {
    const { viewModel } = props;
    const IconLink = viewModel.model.imageUrl
        ? viewModel.model.imageUrl
        : "https://images.unsplash.com/photo-1633412802994-5c058f151b66?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=880&q=80";

    useEffect(() => {
        setPageTitle("Build Stage Admin");
    }, []);

    return (
        <Box
            grid
            dc={"30px 105px 1fr 2fr"}
            opacity={viewModel.getValue("isVisible") ? "100%" : "50%"}
            justifyContent={"center"}
            alignItems={"center"}
            style={{ gap: "30px" }}
            p={"20px 30px"}
            background={"#EFEFEF"}
            borderRadius={"2px"}
        >
            <BuildCheckBox>
                <ThemedDefaultCheckBox displayName="" value={() => viewModel.getValue("isVisible")} command={viewModel.updateIsVisibleCommand} />
            </BuildCheckBox>
            <Box>
                <IconContainer backgroundImage={IconLink} />
                <FileUploaderView
                    viewModel={viewModel}
                    id={viewModel.model.id!}
                    showDropZoneContainer={false}
                    showPlaceholderText={false}
                    uploadButtonStyles={uploadButtonStyles}
                    uploadButtonText="Change icon"
                    isBuildStageAdmin={true}
                />
            </Box>

            <>
                <RenderHTML HTML={viewModel.description} />
            </>
            <BuildMsg>
                <Editor
                    apiKey="g828m3lar5y087wnket2v38pbvjugg2m1091afqssqvjj8ze"
                    value={viewModel.getValue("description")}
                    init={{
                        height: 140,
                        menubar: false,
                        branding: false,
                        browser_spellcheck: true,
                        elementpath: false,
                        plugins:
                            "preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars emoticons",
                        toolbar:
                            "undo redo | bold italic underline strikethrough | subscript superscript | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | fullscreen  preview code | quoteplaceholders | insertfile image media template link anchor codesample",
                        font_formats:
                            "Arapey=arapey,serif; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino;HeadingNow 55 Medium=headingnow55medium, sans-serif; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats",
                        content_style:
                            "@import url('https://basearchitects.blob.core.windows.net/fonts/ArapeyRegularFontFace.css');@import url('https://basearchitects.blob.core.windows.net/fonts/HeadingNow55MediumFontFace.css');",
                        contextmenu: "paste | link image inserttable | cell row column deletetable",
                    }}
                    onEditorChange={viewModel.updateTermsAndConditionText}
                />
            </BuildMsg>
        </Box>
    );
});

import * as React from "react";
import { SVGProps } from "react";
import { theme } from "../../Styles";
import { Box } from "../../Components";
interface Props extends SVGProps<SVGSVGElement> {
    showblankcircle: boolean;
}
export const BuildProgressSVG: React.FC<Props> = (props: Props) => {
    const { showblankcircle, color, ...rest } = props;

    const setColour = () => {
        if (color) {
            return color;
        } else {
            return "#b88e00";
        }
    };

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={68} height={68} {...rest}>
            <defs>
                <filter id="a" x={0} y={0} width={68} height={68} filterUnits="userSpaceOnUse">
                    <feOffset dy={3} />
                    <feGaussianBlur stdDeviation={3} result="blur" />
                    <feFlood floodOpacity={0.251} />
                    <feComposite operator="in" in2="blur" />
                    <feComposite in="SourceGraphic" />
                </filter>
            </defs>
            <g data-name="Group 8266">
                <g data-name="Group 8264">
                    {showblankcircle && (
                        <g data-name="Ellipse 209" transform="translate(9 6)" fill={theme.palette.primary.main} stroke={theme.palette.primary.main} strokeWidth={2.5}>
                            <circle cx={25} cy={25} r={30} stroke="none" />
                            <circle cx={25} cy={25} r={27.75} fill="none" />
                        </g>
                    )}
                    <g filter="url(#a)">
                        <g data-name="Ellipse 209" transform="translate(9 6)" fill="#806302" stroke={setColour()} strokeWidth={2.5}>
                            <circle cx={25} cy={25} r={25} stroke="none" />
                            <circle cx={25} cy={25} r={23.75} fill="none" />
                        </g>
                    </g>
                    <g data-name="noun_brick wall_977839">
                        <g data-name="Group 5512">
                            <path
                                data-name="Path 3670"
                                d="M31.111 24h5.778v2.889h-5.778Zm6.741 0h5.778v2.889h-5.778ZM21 27.852h5.778v2.889H21Zm6.741 0h5.778v2.889h-5.778Zm6.741 0h5.778v2.889h-5.779Zm6.741 0H47v2.889h-5.778ZM24.37 31.704h5.778v2.889H24.37Zm6.741 0h5.778v2.889h-5.778Zm6.741 0h5.778v2.889h-5.778ZM21 35.555h5.778v2.889H21Zm6.741 0h5.778v2.889h-5.778Zm6.741 0h5.778v2.889h-5.779Zm6.741 0H47v2.889h-5.778Z"
                                fill="#FFF"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

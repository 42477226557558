import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { LookupStore } from "Stores/Domain/LookupStore";
import { container } from "tsyringe";
import { Box, setPageTitle, Label, theme, EditSelect, NullCommand, Input, DashedLine } from "Application";
import { CustomercareViewModel } from "./CustomercareViewModel";
import { CoinsPageContainer, CoinsPageContainerWithTabs, CoinsPageNoTitleContainer } from "../../Styles/BaseStyles";
import styled from "@emotion/styled/dist/emotion-styled.cjs";
import { HorizontalBlock } from "../../Components/HorizontalBlock";
import { Route, Routes } from "react-router-dom";
import { ChoicesView } from "../Choices/ChoicesView";
import { SnagListView } from "./SnagListView";
import { AppUrls } from "../../AppUrls";
import { Uploader } from "../../Components/General/Uploader";
import { SnagModel } from "./SnagModel";
import { SnagDetailView } from "./SnagDetail";
import { uniqueId } from "lodash-es";
import { HorizontalSnagBlock } from "../../Components/HorizontalSnagBlock";

import { CustomerCareBlock } from "Components/CustomerCareBlock";
import useMediaQuery from "Application/Hooks/useMediaQuery";
import { dateTimeFormat } from "@shoothill/core";
import moment from "moment";
interface TabButtonProps {
    active: boolean;
}
const TabButton: any = styled(Box)<TabButtonProps>`
    display: flex;
    flex: 25% 0 0;
    width: 200px;
    height: 50px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: ${(props) => (props.active ? "#303D53" : "#325083")};
    //box-shadow: ${(props) => (props.active ? "inset 0 0 6px #3d2f00" : "1px 1px 3px rgba(0, 0, 0, 0.25)")};
    box-shadow: ${(props) => (props.active ? "inset 0 0 6px #3d2f00" : "1px 1px 3px rgba(0, 0, 0, 0.25)")};
    label {
        cursor: pointer;
        font: normal normal 300 18px/30px Lato;
        letter-spacing: -0.18px;
        color: #ffffff;
        text-transform: uppercase;
        margin: 0;
    }
`;

const MobileTabButton = styled(TabButton)`
    display: flex;
    flex: 25% 0 0;
    min-width: 85px;
    height: 50px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: ${(props) => (props.active ? "#303D53" : "#325083")};
    //box-shadow: ${(props) => (props.active ? "inset 0 0 6px #3d2f00" : "1px 1px 3px rgba(0, 0, 0, 0.25)")};
    box-shadow: ${(props) => (props.active ? "inset 0 0 6px #3d2f00" : "1px 1px 3px rgba(0, 0, 0, 0.25)")};
    label {
        cursor: pointer;
        color: #ffffff;
        text-transform: uppercase;
        margin: 0;
        font: normal normal 300 12px/14px Lato;
        letter-spacing: -0.12px;
        color: #ffffff;
        text-transform: uppercase;
        text-align: center;
        span {
        }
    }
`;

const FieldLabel = styled(Label)`
    font-size: 14px;
    font-weight: 400;
`;

const ReportSnagForm = styled(Box)`
    font-size: 12px;
    font-weight: 400;

    .formField {
        margin-bottom: 26px;
        @media (max-width: 767px) {
            margin-bottom: 18px;
        }
        & > div > div:first-child {
        }
    }
    label.placeholder {
        font: normal normal 300 14px/17px Lato;
        letter-spacing: 0.7px;
        color: #4a4a4a;
        text-transform: uppercase;
        margin: 0 0 5px;
    }
    input {
        height: 32px;
        font: normal normal normal 12px/15px Lato;
        letter-spacing: 0.36px;
        color: #707070 !important;
        width: 100% !important;
    }
    input: -internal-autofill-selected {
        background-color: #fff !important;
    }
    .inputbox:focus,
    .inputbox:focus-within {
        border: 1px solid #b88e00 !important;
    }
    input[type="checkbox"] {
        width: 30px !important;
        border-radius: 0 !important;
        height: 30px !important;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #cccbcb !important;
        transform: inherit !important;
        & + div label {
            font: normal normal normal 14px/17px Lato;
            letter-spacing: 0.7px;
            color: #4a4a4a;
            margin: 0;
        }
    }
    button label {
        margin: 0;
        text-decoration: underline;
        font: normal normal normal 9px/10px Lato;
        letter-spacing: 0.24px;
        color: #b88e00;
    }
    .editselect__control {
        border: 1px solid #cccbcb;
        border-radius: 0;
        height: 32px;
        .editselect__input {
            margin: 0;
            padding: 0;
        }
        .editselect__indicator {
            padding: 6px;
        }
        input {
            height: 22px;
        }
    }
`;

const Container = styled(Box)`
    /* overflow-y: auto; */
    max-width: 1200px;
    margin: 0 auto;
    margin: 0 auto;
`;

export const CustomercareView: React.FC = observer(() => {
    const isMobile = useMediaQuery("(max-width: 767px)");
    const [viewModel] = useState(() => new CustomercareViewModel());
    const [showDetails, setShowDetails] = useState(false);
    const lookupStore = container.resolve(LookupStore);
    const [snagDetail, setSnagDetails] = useState<SnagModel>();

    const hideDetails = () => {
        setShowDetails(false);
    };

    useEffect(() => {
        setPageTitle("Customer Experience");
    }, []);

    const tabs = () => {
        return (
            <Box display={"flex"} style={{ gap: "1px" }} mb={"15px"}>
                <TabButton active={viewModel.reportASnagActive} onClick={() => viewModel.setReportASnagActiveCommand.execute()}>
                    <Label color={"white"} fontStyle={"h3"}>
                        FOUND AN ISSUE?
                    </Label>
                </TabButton>
                <TabButton
                    active={viewModel.yourSnagsActive}
                    onClick={() => {
                        setShowDetails(false);
                        setSnagDetails(undefined);
                        viewModel.setYourSnagsActiveCommand.execute();
                    }}
                >
                    <Label color={"white"} fontStyle={"h3"}>
                        Reported Issues ({viewModel.openSnags.length})
                    </Label>
                </TabButton>
                <TabButton
                    active={viewModel.completedIssuesActive}
                    onClick={() => {
                        setShowDetails(false);
                        setSnagDetails(undefined);
                        viewModel.setCompletedIssuesActiveCommand.execute();
                    }}
                >
                    <Label color={"white"} fontStyle={"h3"}>
                        Completed Issues ({viewModel.closedSnags.length})
                    </Label>
                </TabButton>
            </Box>
        );
    };

    const tabsMobile = () => {
        return (
            <Box display={"flex"} style={{ gap: "1px" }} mb={"15px"}>
                <MobileTabButton active={viewModel.reportASnagActive} onClick={() => viewModel.setReportASnagActiveCommand.execute()}>
                    <Label
                        color={"white"}
                        fontStyle={"h3"}
                        style={{ font: "normal normal 300 12px/14px Lato", textAlign: "center", letterSpacing: "-0.12px", color: "#FFFFFF", textTransform: "uppercase" }}
                    >
                        Found <span style={{ font: "normal normal 300 10px/14px Lato", letterSpacing: "-0.1px", display: "block" }}>an issue?</span>
                    </Label>
                </MobileTabButton>
                <MobileTabButton
                    active={viewModel.yourSnagsActive}
                    onClick={() => {
                        setShowDetails(false);
                        setSnagDetails(undefined);
                        viewModel.setYourSnagsActiveCommand.execute();
                    }}
                >
                    <Label
                        color={"white"}
                        fontStyle={"h3"}
                        style={{ font: "normal normal 300 12px/14px Lato", textAlign: "center", letterSpacing: "-0.12px", color: "#FFFFFF", textTransform: "uppercase" }}
                    >
                        REPORTED
                        <span style={{ font: "normal normal 300 10px/14px Lato", textAlign: "center", letterSpacing: "-0.1px", display: "block" }}>
                            ISSUES ({viewModel.openSnags.length})
                        </span>
                    </Label>
                </MobileTabButton>
                <MobileTabButton
                    active={viewModel.completedIssuesActive}
                    onClick={() => {
                        setShowDetails(false);
                        setSnagDetails(undefined);
                        viewModel.setCompletedIssuesActiveCommand.execute();
                    }}
                >
                    <Label
                        color={"white"}
                        fontStyle={"h3"}
                        style={{ font: "normal normal 300 12px/14px Lato", textAlign: "center", letterSpacing: "-0.12px", color: "#FFFFFF", textTransform: "uppercase" }}
                    >
                        Completed
                        <span style={{ font: "normal normal 300 10px/14px Lato", textAlign: "center", letterSpacing: "-0.1px", display: "block" }}>
                            Issues ({viewModel.closedSnags.length})
                        </span>
                    </Label>
                </MobileTabButton>
            </Box>
        );
    };

    const index = () => {
        return (
            <>
                <Box>{viewModel.isMobile ? tabsMobile() : tabs()}</Box>
                {/* <CoinsPageContainerWithTabs title={"Choices & Extras"} tabs={viewModel.isMobile ? tabsMobile() : tabs()}>
                  
                </CoinsPageContainerWithTabs> */}
                {/* <CoinsPageNoTitleContainer
                    ml={viewModel.isMobile ? "10px" : "30px"}
                    mr={viewModel.isMobile ? "10px" : "20px"}
                    style={{ marginTop: viewModel.isMobile ? "20px" : "30px", boxShadow: "0 3px 6px #00000040" }}
                > */}
                <Box showIf={viewModel.reportASnagActive}>
                    {/* <Box my={"22px"}>
                        <Label fontStyle={"h4"} mt={"0"} style={{ font: "normal normal normal 14px/19px Lato", letterSpacing: "-0.14px", color: "#4A4A4A" }}>
                            If you have an issue with your property please report it to us and we work to find a solution.
                        </Label>
                        <Label fontStyle={"h4"} mt={"0"} style={{ font: "normal normal normal 14px/19px Lato", letterSpacing: "-0.14px", color: "#4A4A4A" }}>
                            This is also where you can keep track of your current and previous reported issues.
                        </Label>
                    </Box>
                    <ReportSnagForm>
                        {!viewModel.isMobile && (
                            <Box grid dc={"250px 250px"} alignItems={"center"}>
                                <EditSelect
                                    displayName={"House area"}
                                    command={new NullCommand()}
                                    options={viewModel.houseAreaOptions}
                                    value={() => viewModel.getValue("houseArea")}
                                />
                                <EditSelect
                                    displayName={"Issue type"}
                                    command={new NullCommand()}
                                    options={viewModel.snagTypeOptions}
                                    value={() => viewModel.getValue("snagType")}
                                />
                            </Box>
                        )}
                        {viewModel.isMobile && (
                            <>
                                <Box alignItems={"center"}>
                                    <EditSelect
                                        displayName={"House area"}
                                        command={new NullCommand()}
                                        options={viewModel.houseAreaOptions}
                                        value={() => viewModel.getValue("houseArea")}
                                    />
                                </Box>
                                <Box alignItems={"center"}>
                                    <EditSelect
                                        displayName={"Issue type"}
                                        command={new NullCommand()}
                                        options={viewModel.snagTypeOptions}
                                        value={() => viewModel.getValue("snagType")}
                                    />
                                </Box>
                            </>
                        )}
                        <Box mt={"28px"}>
                            <Input
                                displayName={"Details"}
                                labelColor={"white"}
                                rows={4}
                                command={viewModel.updateDetailsCommand}
                                value={() => viewModel.getValue("details")}
                                multiline={true}
                            />
                        </Box>
                        <Box mt={"12px"}>
                            <Uploader displayName={"Add an image"} command={viewModel.uploadImageCommand} />
                        </Box>
                    </ReportSnagForm> */}
                    <Label mb={"20px"} fontStyle={"h4"} mt={"0"} style={{ font: "normal normal normal 14px/19px Lato", letterSpacing: "-0.14px", color: "#4A4A4A" }}>
                        <b>Congratulations you have completed your new home & our commitment continues</b>
                    </Label>
                    <Label mb={"20px"} fontStyle={"h4"} mt={"0"} style={{ font: "normal normal normal 14px/19px Lato", letterSpacing: "-0.14px", color: "#4A4A4A" }}>
                        <b>Courtesy Call</b>
                    </Label>
                    <Label mb={"20px"} fontStyle={"h4"} mt={"0"} style={{ font: "normal normal normal 14px/19px Lato", letterSpacing: "-0.14px", color: "#4A4A4A" }}>
                        Within a few days of you legally completing the purchase of your new home, you will be contacted by a member of our team, to ensure all is well.  We will
                        then make an appointment to review how you are settling into your new home. We call this the “14-Day Settling In” review.
                    </Label>
                    <Label mb={"20px"} fontStyle={"h4"} mt={"0"} style={{ font: "normal normal normal 14px/19px Lato", letterSpacing: "-0.14px", color: "#4A4A4A" }}>
                        <b>14-day settling-in review</b>
                    </Label>
                    <Label mb={"20px"} fontStyle={"h4"} mt={"0"} style={{ font: "normal normal normal 14px/19px Lato", letterSpacing: "-0.14px", color: "#4A4A4A" }}>
                        Occasionally things may not be quite right and some items may need clarification when you move into your new home.
                    </Label>
                    <Label mb={"20px"} fontStyle={"h4"} mt={"0"} style={{ font: "normal normal normal 14px/19px Lato", letterSpacing: "-0.14px", color: "#4A4A4A" }}>
                        The aim of the “14-Day Settling In” review is to discuss your new home, obtain feedback from you on the process and agree with you on any items that may
                        need sorting out.  We will then coordinate with you as to when these works will be carried out.  After the initial 14-day review and you have agreed that
                        any required works have been completed you will be passed over to our dedicated aftercare service team who are there to deal with any warranty aspects for a
                        two-year period after your purchase.
                    </Label>
                    <Label mb={"20px"} fontStyle={"h4"} mt={"0"} style={{ font: "normal normal normal 14px/19px Lato", letterSpacing: "-0.14px", color: "#4A4A4A" }}>
                        In the first instance if you need to raise an issue you should email{" "}
                        <a href="/" style={{ textDecoration: "underline", color: "#1BA4EB" }}>
                            customerexperience@allison-homes.com
                        </a>{" "}
                        detailing the matter.  The after-care service team will contact you to discuss the situation.  However, if the matter is an emergency there is a dedicated
                        telephone number provided in your handover pack.
                    </Label>
                    <Label mb={"20px"} fontStyle={"h4"} mt={"0"} style={{ font: "normal normal normal 14px/19px Lato", letterSpacing: "-0.14px", color: "#4A4A4A" }}>
                        <b>12-month courtesy call</b>
                    </Label>
                    <Label mb={"20px"} fontStyle={"h4"} mt={"0"} style={{ font: "normal normal normal 14px/19px Lato", letterSpacing: "-0.14px", color: "#4A4A4A" }}>
                        On the 1st anniversary of owning your home, our dedicated team will contact you to ensure that you are enjoying your new home and that any ongoing issues
                        outstanding are being dealt with efficiently.
                    </Label>
                    <Label mb={"20px"} fontStyle={"h4"} mt={"0"} style={{ font: "normal normal normal 14px/19px Lato", letterSpacing: "-0.14px", color: "#4A4A4A" }}>
                        <b>Thank you</b>
                    </Label>
                </Box>
                <Box showIf={viewModel.yourSnagsActive}>
                    {!showDetails && viewModel.yourSnagsActive && (
                        <>
                            {viewModel.openSnags.map((snag) => {
                                return (
                                    <Box
                                        key={uniqueId()}
                                        mb={isMobile ? "10px" : "5px"}
                                        onClick={() => {
                                            setShowDetails(true);
                                            setSnagDetails(snag);
                                        }}
                                    >
                                        <CustomerCareBlock
                                            title={snag.category}
                                            subTitle={snag.description}
                                            description={""}
                                            lastUpdated={
                                                snag.dateClosed || snag.timeClosed
                                                    ? `${snag.dateClosed && snag.dateClosed} ${snag.timeClosed && `@ ${moment(snag.timeClosed, "HHmm").format("HH:mm")}`}`
                                                    : "-"
                                            }
                                            path={""}
                                            view={"snagView"}
                                        />
                                    </Box>
                                );
                            })}
                        </>
                    )}
                    <Box showIf={showDetails}>
                        <SnagDetailView viewModel={viewModel} viewDetails={() => hideDetails()} snagDetail={snagDetail} />
                    </Box>
                    {/* <Box mt={6} pb={6} onClick={() => viewModel.history.push(AppUrls.Client.CustomerCareCurrentSnags + "/open")}>
                        <HorizontalSnagBlock title={`Current Issues (${viewModel.openSnags.length})`} description={""} lastUpdated={""} path={""} view={"snagView"} />
                    </Box>
                    <Box mt={6} pb={6} onClick={() => viewModel.history.push(AppUrls.Client.CustomerCareCurrentSnags + "/closed")}>
                        <HorizontalSnagBlock title={`Completed Issues (${viewModel.closedSnags.length})`} description={""} lastUpdated={""} path={""} view={"snagView"} />
                    </Box> */}
                    {/* <Box ml={3} mr={3} mt={6} mb={6} onClick={() => viewModel.history.push(AppUrls.Client.CustomerCareCurrentSnags + "/rejected")}>
                        <HorizontalSnagBlock title={`Rejected Issues (${viewModel.rejectedSnags.length})`} description={""} lastUpdated={""} path={""} view={"snagView"} />
                    </Box> */}
                    {/* <Box ml={3} mr={3} style={{ borderBottom: "1px solid #acacac", height: "2px", width: "auto" }} /> */}
                </Box>
                <Box showIf={viewModel.completedIssuesActive}>
                    {!showDetails && viewModel.completedIssuesActive && (
                        <>
                            {viewModel.closedSnags.map((snag) => {
                                return (
                                    <Box
                                        key={uniqueId()}
                                        mb={isMobile ? "10px" : "5px"}
                                        onClick={() => {
                                            setSnagDetails(snag);
                                            setShowDetails(true);
                                        }}
                                    >
                                        <CustomerCareBlock
                                            title={snag.category}
                                            subTitle={snag.description}
                                            description={""}
                                            lastUpdated={
                                                snag.dateClosed || snag.timeClosed
                                                    ? `${snag.dateClosed && snag.dateClosed} ${snag.timeClosed && `@ ${moment(snag.timeClosed, "HHmm").format("HH:mm")}`}`
                                                    : "-"
                                            }
                                            path={""}
                                            view={"snagView"}
                                        />
                                    </Box>
                                );
                            })}
                        </>
                    )}
                    <Box showIf={showDetails}>
                        <SnagDetailView viewModel={viewModel} viewDetails={() => hideDetails()} snagDetail={snagDetail} />
                    </Box>
                </Box>
                {/* </CoinsPageNoTitleContainer> */}
            </>
        );
    };
    return (
        <>
            <Box background={"#325083"} padding={"25px 15px"}>
                <Container id={"rootcontainer"}>
                    <Label
                        fontStyle={"h1"}
                        mt={"0"}
                        mb={"6px"}
                        style={{
                            letterSpacing: "-0.24px",
                            color: "#FFFFFF",
                            textTransform: "uppercase",
                            opacity: 1,
                            fontSize: "24px",
                            lineHeight: "30px",
                            fontWeight: "normal",
                        }}
                    >
                        Customer Experience
                    </Label>
                    <Label
                        fontStyle={"h3"}
                        style={{ letterSpacing: "-0.24px", fontSize: "16px", lineHeight: "21px", color: "#FFFFFF", textTransform: "uppercase", fontWeight: 300 }}
                    >
                        Home {Number.isNaN(parseInt(viewModel.getValue("plotNumber"), 10)) ? "" : parseInt(viewModel.getValue("plotNumber"), 10)}{" "}
                        {viewModel.getValue("developmentName")}
                    </Label>
                    <FieldLabel fontStyle={"h4"} style={{ letterSpacing: "-0.15px", color: "#DCB224", textTransform: "uppercase", fontSize: "10px", lineHeight: "21px" }}>
                        {`${viewModel.getValue("address1") !== null ? viewModel.getValue("address1") : ""} ${
                            viewModel.getValue("address2") !== null ? viewModel.getValue("address2") : ""
                        } ${viewModel.getValue("address3") !== null ? viewModel.getValue("address3") : ""} ${
                            viewModel.getValue("address4") !== null ? viewModel.getValue("address4") : ""
                        } ${viewModel.getValue("postcode") !== null ? viewModel.getValue("postcode") : ""}`}
                    </FieldLabel>
                </Container>
            </Box>
            <Box padding={"15px"}>
                <Container id={"rootcontainer"}>
                    <Routes>
                        <Route index element={index()} />
                        <Route path={"currentsnags/:category"} element={<SnagListView viewModel={viewModel} />} />
                        {/* <Route path={"currentsnags/detail/:reference"} element={<SnagDetailView viewModel={viewModel} />} /> */}
                    </Routes>
                </Container>
            </Box>
        </>
    );
});

import * as React from "react";
import { SVGProps } from "react";
import { theme } from "../../Styles";

export const DocumentIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={19.239} height={25} {...props}>
        <g data-name="Group 8330">
            <path
                data-name="Subtraction 4"
                d="M17.985 23.815h-6.457v-.016H.405v-22.6h12.046v5.017h5.534v17.6Zm-13.443-4.5a.619.619 0 1 0 0 1.237h9.95a.619.619 0 1 0 0-1.237Zm0-3.4a.619.619 0 1 0 0 1.237h9.95a.619.619 0 1 0 0-1.237Zm0-3.4a.619.619 0 1 0 0 1.237h9.95a.619.619 0 1 0 0-1.237Zm0-3.4a.619.619 0 1 0-.001 1.23h9.95a.619.619 0 1 0 0-1.237Zm0-3.4a.619.619 0 1 0 0 1.238h4.353a.619.619 0 1 0 0-1.238Z"
            />
            <path
                data-name="Subtraction 6"
                d="M17.416 25H1.832a1.826 1.826 0 0 1-1.823-1.824v-2.343H0v-1.282h.009V1.824A1.826 1.826 0 0 1 1.832 0h10.51a1.814 1.814 0 0 1 1.238.487l2.93 2.931 2.19 2.194a1.794 1.794 0 0 1 .539 1.288v16.276A1.826 1.826 0 0 1 17.416 25ZM1.832 1.282a.542.542 0 0 0-.542.542v21.352a.542.542 0 0 0 .542.542h15.584a.542.542 0 0 0 .542-.542V7.051h-3.947a1.826 1.826 0 0 1-1.824-1.824V1.282Zm11.637.907v3.039a.542.542 0 0 0 .542.542h3.039Z"
            />
        </g>
    </svg>
);

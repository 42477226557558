import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import UserViewModel from "Views/Admin/Users/UserViewModel";
import { Box, Button, NullCommand, formatDate } from "../../../Application";
import { IColumn } from "@fluentui/react";
import { UserModel } from "./UserModel";
import { DeleteIcon } from "../../../Assets";
import { ThemedDataTable } from "../../../Styles/ThemedPrimitives/Style1/ThemedDataTable";
import { EmailIcon } from "../../../Assets/Icons/EmailIcon";
import { EditIcon } from "../../../Assets/Icons/EditIcon";
import { nanoid } from "nanoid";
import { ThemedDialog } from "../../../Styles/ThemedPrimitives/Style1/ThemedDialog";
import styled from "@emotion/styled";
import useMediaQuery from "Application/Hooks/useMediaQuery";
import { LockSVG } from "Assets/SVGs/Locksvg";
import { DisableLocksvg } from "Assets/SVGs/DisableLocksvg";
import { container } from "tsyringe";
import { AccountStore } from "../../../Stores/Domain";
import { UnLockSVG } from "Assets/SVGs/UnLockSVG";

const UserListTable = styled(Box)`
    .ms-DetailsHeader {
        grid-template-columns: 14% 20% 14% 14% 24% 14% 14%;
        .ms-DetailsHeader-cell:last-child {
            .ms-DetailsHeader-cellTitle {
                justify-content: center;
            }
        }
        @media (max-width: 767px) {
            grid-template-columns: 150px 250px 150px 150px 220px 120px;
        }
    }
    .ms-DetailsRow-fields {
        grid-template-columns: 14% 20% 14% 14% 24% 14% 14%;
        @media (max-width: 767px) {
            grid-template-columns: 150px 250px 150px 150px 220px 120px;
        }
    }
`;

export const UsersList: React.FC = observer(() => {
    const [viewModel] = useState(() => new UserViewModel());
    const accountStore = container.resolve(AccountStore);
    const isMobile = useMediaQuery("(max-width: 767px)");

    useEffect(() => {
        if (!accountStore.isInRole("admin") && !accountStore.isInRole("sales admin")) {
            viewModel.history.push("/");
        }
    }, []);
    const getDataTableColumns = (): IColumn[] => {
        return [
            {
                key: "fullNameCol",
                name: "Name",
                fieldName: "name",
                flexGrow: 1,
                onRenderField: (data) => {
                    return (
                        <Box display={"flex"} alignItems={"center"} style={{ gap: "10px", whiteSpace: "initial" }} p={"10px"}>
                            {data?.item.firstName + "  " + data?.item.lastName}
                        </Box>
                    );
                },
            },
            {
                key: "emailCol",
                name: "Email address",
                fieldName: "emailAddress",
                onRenderField: (data) => {
                    return (
                        <Box display={"flex"} alignItems={"center"} style={{ gap: "10px", whiteSpace: "initial" }}>
                            <EmailIcon />
                            {data?.item.emailAddress}
                        </Box>
                    );
                },
            },
            {
                key: "roleCol",
                name: "Role",
                fieldName: "userRoles",
                flexGrow: 1,
                onRenderField: (data) => {
                    return (
                        <div key={nanoid()} role={"gridcell"} style={{ gap: "10px", textTransform: "capitalize", whiteSpace: "initial" }}>
                            {data?.item.userRoles.sort().join(", ")}
                        </div>
                    );
                },
            },
            {
                key: "lastLoginDateCol",
                name: "Last logged in",
                fieldName: "lastLoginDate",
                onRenderField: (data) => {
                    return (
                        <div key={nanoid()} style={{ gap: "10px" }}>
                            {formatDate(data?.item.lastLoginDate, "DD/MM/YYYY @ HH:mm")}
                        </div>
                    );
                },
            },
            {
                key: "createByCol",
                name: "CreatedBy",
                fieldName: "createdBy",
                onRenderField: (data) => {
                    return (
                        <div key={nanoid()} role={"gridcell"} style={{ gap: "10px", textTransform: "capitalize", whiteSpace: "initial" }}>
                            {viewModel.getUserName(data?.item?.createdBy)}
                        </div>
                    );
                },
            },
            {
                key: "actionsCol",
                name: "Actions",
                onRender: (item: UserModel) => {
                    return (
                        <Box display={"flex"} alignItems={"center"} height={"100%"} justifyContent={"center"} style={{ gap: "20px" }}>
                            {item.accessFailedCount > 7 ? (
                                <Box
                                    title={"Unlock User"}
                                    display={"flex"}
                                    alignItems={"center"}
                                    style={{ cursor: "pointer" }}
                                    onClick={() => viewModel.unlockUserWithConfirmDialogCommand.execute(item)}
                                >
                                    <LockSVG />
                                </Box>
                            ) : (
                                <Box
                                    title={"Lock User"}
                                    display={"flex"}
                                    alignItems={"center"}
                                    style={{ cursor: "pointer" }}
                                    onClick={() => viewModel.lockUserWithConfirmDialogCommand.execute(item)}
                                >
                                    <UnLockSVG />
                                </Box>
                            )}
                            <Box title={"Edit User"} display={"flex"} alignItems={"center"} onClick={() => viewModel.editUserCommand.execute(item)}>
                                <EditIcon />
                            </Box>
                            <Box title={"Delete User"} display={"flex"} alignItems={"center"} onClick={() => viewModel.deleteUserWithConfirmDialogCommand.execute(item)}>
                                <DeleteIcon color={"red"} />
                            </Box>
                        </Box>
                    );
                },
            },
        ] as IColumn[];
    };

    return (
        <>
            <Box id="tablecontainer">
                <Box flexGrow={1} textAlign="left" mb={"15px"}>
                    <Button
                        paletteColor="secondary"
                        command={viewModel.newUserCommand}
                        displayName={"ADD NEW ADMIN USER"}
                        style={{
                            background: "#B88E00",
                            maxWidth: "100%",
                            width: isMobile ? "50%" : "25%",
                            borderRadius: 0,
                            height: "30px",
                            font: "normal normal 300 12px/15px Lato",
                            letterSpacing: "0.36px",
                            color: "#FFFFFF",
                            textTransform: "uppercase",
                            padding: isMobile ? "8px 15px" : "8px 30px",
                        }}
                    />
                </Box>
                <UserListTable>
                    <ThemedDataTable data={viewModel.getUsers} columns={getDataTableColumns()} command={new NullCommand()} value={() => viewModel.selectedRows} />
                </UserListTable>
            </Box>
            <ThemedDialog
                command={viewModel.deleteUserCommand}
                dismissCommand={viewModel.hideDialogCommand}
                title={`Delete User ${viewModel.userToDelete?.emailAddress}`}
                message={"Are you sure you want to delete this user?"}
                show={viewModel.showDialog}
                paletteColor={"secondary"}
            />
            <ThemedDialog
                command={viewModel.unlockUserCommand}
                dismissCommand={viewModel.hideUnlockDialogCommand}
                title={`Unlock User ${viewModel.userToUnlock?.emailAddress}`}
                message={"Are you sure you want to unlock this user?"}
                show={viewModel.showUnlockDialog}
                paletteColor={"secondary"}
            />
            <ThemedDialog
                command={viewModel.lockUserCommand}
                dismissCommand={viewModel.hideLockDialogCommand}
                title={`Lock User ${viewModel.userToUnlock?.emailAddress}`}
                message={"Are you sure you want to lock this user?"}
                show={viewModel.showLockDialog}
                paletteColor={"secondary"}
            />
        </>
    );
});

import React from "react";
import { observer } from "mobx-react-lite";

import styled from "@emotion/styled";
import { YourhomeViewModel } from "../YourhomeViewModel";

import { Box, Label, formatDate } from "Application";

const FieldTitle = styled(Label)`
    font-size: 14px;
    font-weight: 700;
    line-height: "17px";
    margin-top: 0;
`;

const FieldLabel = styled(Label)`
    font-size: 14px;
    font-weight: 400;
    line-height: "17px";
    margin-top: 0;
`;

interface props {
    viewModel: YourhomeViewModel;
}

export const OverviewGridView: React.FC<props> = observer((props: props) => {
    const viewModel = props.viewModel;

    const DesktopPanel = () => {
        return (
            <Box py={"28px"} grid dc={"176px auto"} rowGap={"10px"} borderBottom={"1px solid #BEBEBE"}>
                <FieldTitle>Sales Executive:</FieldTitle>
                <FieldLabel fontStyle={"h4"}>{viewModel.getValue("salesAgentName")}</FieldLabel>
                {/* <FieldTitle>Address:</FieldTitle>
                <FieldLabel fontStyle={"h4"}>
                    {`${viewModel.getValue("address1") !== null ? viewModel.getValue("address1") : ""} ${
                        viewModel.getValue("address2") !== null ? viewModel.getValue("address2") : ""
                    } ${viewModel.getValue("address3") !== null ? viewModel.getValue("address3") : ""} ${
                        viewModel.getValue("address4") !== null ? viewModel.getValue("address4") : ""
                    } ${viewModel.getValue("postcode") !== null ? viewModel.getValue("postcode") : ""}`}
                </FieldLabel> */}
                <FieldTitle>Development Contact No:</FieldTitle>
                <FieldLabel fontStyle={"h4"}>{viewModel.getValue("developmentContactNumber")}</FieldLabel>
                <FieldTitle>Development Email:</FieldTitle>
                <a href={`mailto:${viewModel.getValue<string>("developmentEmail")}`} style={{ textDecorationColor: "#00B9EE" }}>
                    <FieldLabel style={{ textDecorationColor: "#00B9EE" }} color={"blue"}>
                        {" "}
                        {viewModel.getValue<string>("developmentEmail")}
                    </FieldLabel>
                </a>
            </Box>
        );
    };

    const MobilePanel = () => {
        return (
            <Box py={"28px"} grid dc={"1fr"} rowGap={"22px"} borderBottom={"1px solid #BEBEBE"}>
                <Box grid dc={"1fr"} rowGap={"4px"}>
                    <FieldTitle>Sales Executive:</FieldTitle>
                    <FieldLabel fontStyle={"h4"}>{viewModel.getValue("salesAgentName")}</FieldLabel>
                </Box>
                <Box grid dc={"1fr"} rowGap={"4px"}>
                    <FieldTitle>Development Contact No:</FieldTitle>
                    <FieldLabel fontStyle={"h4"}>{viewModel.getValue("developmentContactNumber")}</FieldLabel>
                </Box>
                {/* <Box grid dc={"1fr"} rowGap={"4px"}>
                    <FieldTitle>Deposit:</FieldTitle>
                    <FieldLabel fontStyle={"h4"}>£{viewModel.getValue("deposit")}</FieldLabel>
                </Box> */}
                <Box grid dc={"1fr"} rowGap={"4px"}>
                    <FieldTitle>Development Email:</FieldTitle>
                    <a href={`mailto:${viewModel.getValue<string>("developmentEmail")}`} style={{ textDecorationColor: "#00B9EE" }}>
                        <FieldLabel style={{ textDecorationColor: "#00B9EE" }} color={"blue"}>
                            {" "}
                            {viewModel.getValue<string>("developmentEmail")}
                        </FieldLabel>
                    </a>
                </Box>
            </Box>
        );
    };

    return <>{!viewModel.isMobile ? <>{DesktopPanel()}</> : <>{MobilePanel()}</>}</>;
});

import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Box, setPageTitle, Label, formatDate } from "Application";
import { StagesViewModel } from "./StagesViewModel";
import styled from "@emotion/styled";
import SalesComplete from "Assets/SVGs/SalesComplete";
import WriteIcon from "Assets/SVGs/WriteIcon";

const Container = styled(Box)`
    background-color: transparent;
    border-radius: 10px;
    min-height: 100px;
    width: 100%;
`;

const DetailBox = styled(Box)`
    position: absolute;
    top: 160px;
    width: 88vw;
    left: -100px;
    z-index: 10;
    left: 50%;
    transform: translateX(-50%);
`;

interface Props {
    viewModel: StagesViewModel;
    index: number;
    total: number;
    isFocused: boolean;
}
export const StagesMobileView: React.FC<Props> = observer((props: Props) => {
    const { viewModel } = props;
    const divRef = React.useRef<any>(null);

    useEffect(() => {
        setPageTitle("Stages");
    }, []);

    useEffect(() => {
        if (props.index === props.total) {
            divRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
        }
    });

    const description = props.viewModel.model.description;

    return (
        <div ref={divRef} id={props.index == props.total ? "finalstage" : "stage" + props.index}>
            {!props.isFocused && (
                <Box
                    height={"96px"}
                    width={"96px"}
                    position={"relative"}
                    background={"#fff"}
                    borderRadius={"100%"}
                    opacity={"40%"}
                    style={{ margin: "0 auto", transform: "scale(0.75)" }}
                >
                    <SalesComplete />
                    {props.viewModel.model.isVisible && (
                        <Box position={"absolute"} bottom={"0"} right={"0"}>
                            <WriteIcon />
                        </Box>
                    )}
                </Box>
            )}

            {props.isFocused && (
                <>
                    <Box
                        height={"96px"}
                        width={"96px"}
                        position={"relative"}
                        background={"#fff"}
                        borderRadius={"100%"}
                        style={{ boxShadow: "0 5px 8px #00000045", margin: "0 auto" }}
                    >
                        <SalesComplete />
                        {props.viewModel.model.isVisible && (
                            <Box position={"absolute"} bottom={"0"} right={"0"}>
                                <WriteIcon />
                            </Box>
                        )}
                    </Box>
                    <DetailBox
                        flexBox
                        background={props.viewModel.model.isVisible ? "#F0F9EF" : "#fff"}
                        borderRadius={"10px"}
                        border={"2px solid transparent"}
                        borderColor={props.viewModel.model.isVisible ? "#D8F1D6" : "#e7e7e7"}
                        style={{ gap: 0 }}
                        position={"relative"}
                        alignItems="center"
                    >
                        <Box
                            height={"40px"}
                            width={"40px"}
                            border={"2px solid transparent"}
                            borderLeftColor={props.viewModel.model.isVisible ? "#D8F1D6" : "#e7e7e7"}
                            borderBottomColor={props.viewModel.model.isVisible ? "#D8F1D6" : "#e7e7e7"}
                            background={props.viewModel.model.isVisible ? "#F0F9EF" : "#fff"}
                            top={"-20px"}
                            position={"absolute"}
                            left={"0"}
                            right={"0"}
                            margin={"0 auto"}
                            borderRadius={"0 0 0 5px"}
                            style={{ transform: "rotate(135deg)" }}
                        ></Box>
                        <Container p={"15px"} textAlign={"left"} position={"relative"} zIndex={1}>
                            <Label
                                fontStyle={"h4"}
                                color={"black"}
                                mt={"0"}
                                style={{
                                    font: "normal normal bold 11px/18px Lato",
                                    letterSpacing: "-0.19px",
                                    color: "#333333",
                                    gap: "5px",
                                    display: "flex",
                                    borderBottom: "1px dashed #BEBEBE",
                                    margin: "0 0 12px",
                                    paddingBottom: "12px",
                                }}
                            >
                                Stage {props.index} of {props.total}
                                <span
                                    style={{
                                        font: "normal normal normal 9px/18px Lato",
                                        letterSpacing: "-0.14px",
                                        color: props.viewModel.model.isVisible ? "#1DC10F" : "#00B9EE",
                                    }}
                                >
                                    {props.viewModel.model.isVisible ? "(complete)" : "(current)"}
                                </span>
                            </Label>
                            <Label
                                fontStyle={"h3"}
                                mt={0}
                                color={"black"}
                                style={{ font: "normal normal bold 13px/20px Lato", marginTop: "0", letterSpacing: "-0.22px", color: "#4A4A4A" }}
                            >
                                {viewModel.getValue("title")}
                            </Label>
                            <Label
                                fontStyle={"h5"}
                                color={"black"}
                                mt={0}
                                style={{ font: "normal normal normal 8px/15px Lato", marginTop: "0", letterSpacing: "0px", color: "#333333" }}
                            >
                                {viewModel.getValue("stageDateUTC") ? formatDate(viewModel.getValue("stageDateUTC")) : "-"}
                            </Label>
                            <Label
                                mt={"10px"}
                                fontStyle={"h4"}
                                color={"black"}
                                style={{ fontWeight: 400, font: "normal normal normal 11px/15px Lato", letterSpacing: "0px", color: "#333333" }}
                            >
                                {description.replace(/(<([^>]+)>)/gi, " ")}
                            </Label>
                        </Container>
                    </DetailBox>
                </>
            )}
        </div>
    );
});

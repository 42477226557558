import * as React from "react";
import { SVGProps } from "react";
import { theme } from "../../Styles";
import { Box } from "../../Components";
interface Props extends SVGProps<SVGSVGElement> {
    showblankcircle?: boolean;
}
export const DocumentsSVG: React.FC<Props> = (props: Props) => {
    const { showblankcircle, ...rest } = props;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={68} height={68} {...rest}>
            <defs>
                <filter id="a" x={0} y={0} width={68} height={68} filterUnits="userSpaceOnUse">
                    <feOffset dy={3} />
                    <feGaussianBlur stdDeviation={3} result="blur" />
                    <feFlood floodOpacity={0.251} />
                    <feComposite operator="in" in2="blur" />
                    <feComposite in="SourceGraphic" />
                </filter>
            </defs>
            <g data-name="Group 8266">
                <g data-name="Group 8264">
                    {showblankcircle && (
                        <g data-name="Ellipse 209" transform="translate(9 6)" fill={theme.palette.primary.main} stroke={theme.palette.primary.main} strokeWidth={2.5}>
                            <circle cx={25} cy={25} r={30} stroke="none" />
                            <circle cx={25} cy={25} r={27.75} fill="none" />
                        </g>
                    )}
                    <g filter="url(#a)">
                        <g data-name="Ellipse 209" transform="translate(9 6)" fill="#806302" stroke="#b88e00" strokeWidth={2.5}>
                            <circle cx={25} cy={25} r={25} stroke="none" />
                            <circle cx={25} cy={25} r={23.75} fill="none" />
                        </g>
                    </g>
                    <g data-name="Group 5580">
                        <path
                            data-name="Subtraction 7"
                            d="M43.166 43.166h-15v-20h10v4.167h5v15.833Zm-12.145-3.942a.562.562 0 0 0 0 1.124h8.989a.562.562 0 0 0 0-1.124Zm0-3.089a.562.562 0 1 0 0 1.123h8.989a.562.562 0 1 0 0-1.123Zm0-3.091a.562.562 0 1 0 0 1.123h8.989a.562.562 0 0 0 0-1.123Zm0-3.089a.562.562 0 1 0 0 1.123h8.989a.562.562 0 0 0 0-1.123Zm0-3.091a.562.562 0 1 0 0 1.123h3.933a.562.562 0 1 0 0-1.123Z"
                            fill="#fff"
                        />
                        <g data-name="Group 5579">
                            <path
                                data-name="Path 3618"
                                d="M40.292 23.823v-3.11A1.717 1.717 0 0 0 38.579 19H25.713A1.717 1.717 0 0 0 24 20.713v17.922a1.717 1.717 0 0 0 1.713 1.713h1.377V42.4a1.6 1.6 0 0 0 1.6 1.6h13.656a1.6 1.6 0 0 0 1.6-1.6V28.138a1.578 1.578 0 0 0-.469-1.129Zm1.733 3.323h-2.663a.476.476 0 0 1-.475-.475v-2.663ZM27.09 23.688v15.537h-1.377a.59.59 0 0 1-.59-.59V20.713a.59.59 0 0 1 .59-.59h12.866a.59.59 0 0 1 .59.59V22.7l-.14-.14a1.583 1.583 0 0 0-1.129-.47h-9.212a1.6 1.6 0 0 0-1.598 1.598ZM42.82 42.4a.476.476 0 0 1-.475.475H28.688a.476.476 0 0 1-.475-.475V23.688a.476.476 0 0 1 .475-.475h9.076v3.458a1.6 1.6 0 0 0 1.6 1.6h3.456Z"
                                fill="#fff"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

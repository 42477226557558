import React, { useState } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { observer } from "mobx-react-lite";
import { YourhomeViewModel } from "../YourhomeViewModel";
import { AutoGrid, Box } from "Application";
import { uniqueId } from "lodash-es";

interface props {
    viewModel: YourhomeViewModel;
}

export const GalleryView: React.FC<props> = observer((props: props) => {
    const viewModel = props.viewModel;

    const [open, setOpen] = useState(false);
    const [data, setData] = useState(null);

    const onOpenModal = (item: any) => {
        setOpen(true);
        setData(item);
    };
    const onCloseModal = () => {
        setOpen(false);
    };

    const DesktopPanel = () => {
        return (
            <AutoGrid dc={"1fr 1fr 1fr 1fr"} tabletColumns={"1fr 1fr 1fr"} mc={"1fr 1fr"} columnGap={"30px"} rowGap={"30px"} my={"40px"}>
                {viewModel.model.houseTypeImages.map((item, index) => {
                    return (
                        <Box key={uniqueId()} flexBox columnGap={"30px"} style={{ cursor: "pointer" }}>
                            <img
                                onClick={() => {
                                    onOpenModal(index);
                                }}
                                key={index}
                                src={item.fileURL}
                                alt={item.fileName}
                                style={{ opacity: open ? "50%" : "100%", pointerEvents: open ? "none" : "auto", width: "100%", maxWidth: "100%", objectFit: "cover" }}
                            />

                            {open && data === index && data !== null && (
                                <Modal open={open} onClose={onCloseModal} center closeOnOverlayClick={false}>
                                    <img src={item.fileURL} alt={item.fileName} key={index} style={{ width: "750px", objectFit: "cover" }} />
                                </Modal>
                            )}
                        </Box>
                    );
                })}
            </AutoGrid>
        );
    };

    const MobilePanel = () => {
        return (
            <AutoGrid dc={"1fr 1fr 1fr 1fr"} tabletColumns={"1fr 1fr 1fr"} mc={"1fr 1fr"} columnGap={"30px"} rowGap={"30px"} my={"40px"}>
                {viewModel.model.houseTypeImages.map((item, index) => {
                    return (
                        <Box display={"flex"} flexWrap={"wrap"} alignItems={"center"} justifyContent={"center"} style={{ gap: "30px", cursor: "pointer" }} key={uniqueId()}>
                            <img
                                onClick={() => {
                                    onOpenModal(index);
                                }}
                                key={index}
                                src={item.fileURL}
                                alt={item.fileName}
                                style={{
                                    opacity: open ? "50%" : "100%",
                                    pointerEvents: open ? "none" : "auto",
                                    width: "100%",
                                    maxWidth: "100%",
                                    objectFit: "cover",
                                }}
                            />
                            {open && data === index && data !== null && (
                                <Modal open={open} onClose={onCloseModal} center closeOnOverlayClick={false}>
                                    <img src={item.fileURL} alt={item.fileName} style={{ width: "260px", objectFit: "cover" }} />
                                </Modal>
                            )}
                        </Box>
                    );
                })}
            </AutoGrid>
        );
    };

    return <>{!viewModel.isMobile ? <>{DesktopPanel()}</> : <>{MobilePanel()}</>}</>;
});

import styled from "@emotion/styled";
import { Box } from "./General";
import React from "react";
import { container } from "tsyringe";
import { DomainStore } from "../Stores/Domain/DomainStore";
import { Label, RelayCommand } from "../Application";
import { getHistory, isNullOrEmpty } from "@shoothill/core";
import useMediaQuery from "Application/Hooks/useMediaQuery";

const StyledBlock = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 6px;
    transition: all 0.3s ease;
    //margin: 0 50px 0 50px;
    background: #efefef;
    padding: 12px 30px;
    border-radius: 2px;
    position: relative;
    cursor: pointer;
    @media screen and (max-width: 767px) {
        padding: 6px 10px;
        display: block;
    }
`;
const IconContainer = styled(Box)`
    margin-top: 0;
    margin-left: -35px;
    display: flex;
    align-items: center;
`;
interface BlockProps {
    icon?: JSX.Element;
    title: string;
    image?: string;
    description?: string;
    subTitle?: string;
    lastUpdated?: string;
    path: string;
    applyTransform?: boolean;
    view?: "homeView" | "panelView" | "snagView";
    style?: any;
}
export const CustomerCareBlock: React.FC<BlockProps> = (props) => {
    const isMobile = useMediaQuery("(max-width: 767px)");
    const domainStore = container.resolve(DomainStore);
    const getStyles = () => {
        if (props.applyTransform) {
            return { transform: "scale(1)", transformOrigin: "10px 8px" };
        } else {
            return {};
        }
    };
    const gotoPageCommand = new RelayCommand(() => {
        domainStore.setSideBarClosed(false);
        getHistory().push(props.path);
    });
    return (
        <StyledBlock id={"block"} onClick={gotoPageCommand.execute} style={props.style!}>
            {/* <img src={props.image} className="img-fluid" alt="image" style={{ width: "120px", height: "88px", objectFit: "cover", background: "#eee" }} /> */}
            <Box flexBox style={{ gap: 0, width: "calc(100% - 200px);" }}>
                {props.icon && <IconContainer style={getStyles()}>{props.icon}</IconContainer>}
                <Box flexBox flexDirection={"column"} justifyContent={"center"} style={{ gap: 0 }}>
                    <Label
                        fontStyle={"h4"}
                        style={{ textTransform: "uppercase", font: "normal normal bold 14px/17px Lato", letterSpacing: "-0.21px", color: "#4A4A4A", margin: 0, cursor: "pointer" }}
                    >
                        {props.title}
                    </Label>
                    {!isNullOrEmpty(props.subTitle) && (
                        <Label fontStyle={"h6"} style={{ font: "normal normal normal 12px/17px Lato", letterSpacing: "-0.18px", color: "#4A4A4A", margin: "0", cursor: "pointer" }}>
                            {props.subTitle ? decodeURIComponent(escape(props.subTitle)) : ""}
                        </Label>
                    )}
                </Box>
            </Box>
            <Box showIf={props.view !== "homeView"} style={{ minWidth: "200px", cursor: "pointer" }}>
                <Label fontStyle={"h5"}>{props.description}</Label>
                {!isNullOrEmpty(props.lastUpdated) && (
                    <Label
                        fontStyle={"h6"}
                        color={"blue"}
                        style={{
                            font: isMobile ? "normal normal bold 10px/25px Lato" : "normal normal bold 12px/25px Lato",
                            letterSpacing: "-0.18px",
                            color: "#1BA4EB",
                            textTransform: "uppercase",
                            margin: 0,
                        }}
                    >
                        {props.lastUpdated == "-" ? "" : `Last updated ${props.lastUpdated}`}
                    </Label>
                )}
            </Box>
        </StyledBlock>
    );
};
CustomerCareBlock.defaultProps = {
    applyTransform: true,
    view: "panelView",
};

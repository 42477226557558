import * as React from "react";
import { SVGProps } from "react";

export const BackPageSVG = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
        <g id="Group_8686" data-name="Group 8686" transform="translate(-120 -289)">
            <circle id="Ellipse_244" data-name="Ellipse 244" cx="15" cy="15" r="15" transform="translate(120 289)" fill="#325083" />
            <path
                id="Path_3979"
                data-name="Path 3979"
                d="M10.087,6.9h5.3a1.595,1.595,0,1,1,0,3.19h-5.3v5.3a1.595,1.595,0,1,1-3.19,0V6.9Z"
                transform="translate(119 304.009) rotate(-45)"
                fill="#fff"
                fillRule="evenodd"
            />
        </g>
    </svg>
);

import * as React from "react";
import { SVGProps } from "react";
import { theme } from "../../Styles";
import { Box } from "../../Components";
interface Props extends SVGProps<SVGSVGElement> {
    showblankcircle?: boolean;
}
export const CustomerCareSVG: React.FC<Props> = (props: Props) => {
    const { showblankcircle, ...rest } = props;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={68} height={68} {...rest}>
            <defs>
                <filter id="a" x={0} y={0} width={68} height={68} filterUnits="userSpaceOnUse">
                    <feOffset dy={3} />
                    <feGaussianBlur stdDeviation={3} result="blur" />
                    <feFlood floodOpacity={0.251} />
                    <feComposite operator="in" in2="blur" />
                    <feComposite in="SourceGraphic" />
                </filter>
            </defs>
            <g data-name="Group 8266">
                <g data-name="Group 8264">
                    {showblankcircle && (
                        <g data-name="Ellipse 209" transform="translate(9 6)" fill={theme.palette.primary.main} stroke={theme.palette.primary.main} strokeWidth={2.5}>
                            <circle cx={25} cy={25} r={30} stroke="none" />
                            <circle cx={25} cy={25} r={27.75} fill="none" />
                        </g>
                    )}
                    <g filter="url(#a)">
                        <g data-name="Ellipse 209" transform="translate(9 6)" fill="#806302" stroke="#b88e00" strokeWidth={2.5}>
                            <circle cx={25} cy={25} r={25} stroke="none" />
                            <circle cx={25} cy={25} r={23.75} fill="none" />
                        </g>
                    </g>
                    <g fill="#fff">
                        <path
                            data-name="Path 3673"
                            d="M41.157 27.919c.316-.63.269-1-.159-1.292s-.682-.2-1.409.5l-2.342-1.951c.112-.179.207-.358.326-.518a.408.408 0 0 0 .02-.48 3.823 3.823 0 0 0-3.79-2.24h-.374c.127-.152.219-.286.333-.4a4.635 4.635 0 0 1 2.446-1.15 3.691 3.691 0 0 1 2.867.669 4.7 4.7 0 0 0 .66.391 1.045 1.045 0 0 0 .5.122 1.521 1.521 0 0 1 1.1.368 8.982 8.982 0 0 1 .886.747 2.225 2.225 0 0 1 .368.595 2.17 2.17 0 0 1 .132.436c.207.816.747 1.033 1.468.575.625.533 1.262 1.063 1.882 1.61a.632.632 0 0 1 .075.921c-.132.177-.286.334-.433.5l-1.744 1.884c-.386.421-.65.448-1.1.1a204.11 204.11 0 0 0-1.712-1.387Z"
                        />
                        <path
                            data-name="Path 3674"
                            d="m39.123 27.853-1.105 1.4L27.961 41.94a1.272 1.272 0 0 1-1.929.2c-.468-.388-.941-.772-1.406-1.165a1.217 1.217 0 0 1-.132-1.834q6.014-6.661 12.031-13.321a.816.816 0 0 1 .077-.057Z"
                        />
                        <path
                            data-name="Path 3675"
                            d="M46.903 37.371a4.946 4.946 0 0 0-.615-1.528 4.4 4.4 0 0 0-5.645-1.553.212.212 0 0 1-.179 0c-.804-.515-1.625-1.008-2.404-1.56q-.378-.266-.747-.548l-1.993 2.392q.567.5 1.11 1.035c.6.587 1.155 1.222 1.722 1.842a.351.351 0 0 1 .077.249 4.286 4.286 0 0 0 .131 1.742 4.336 4.336 0 0 0 2.445 2.84 4.214 4.214 0 0 0 3.375.082.8.8 0 0 0 .117-.082.942.942 0 0 0-.082-.085l-2.489-2.061a.316.316 0 0 1-.119-.184c-.03-.54-.05-1.083-.067-1.625a.271.271 0 0 1 .06-.162q.358-.45.729-.891a.271.271 0 0 1 .154-.08c.518-.082 1.033-.164 1.551-.231a.368.368 0 0 1 .249.072c.635.52 1.262 1.045 1.889 1.573.229.192.453.391.7.61.017-.1.027-.149.032-.2l.092-.791-.07-.747q-.012-.051-.023-.109Z"
                        />
                        <path
                            data-name="Path 3676"
                            d="M30.823 26.238c-.192-.222-.316-.416-.149-.7a.286.286 0 0 0 .022-.08 4.41 4.41 0 0 0-1.936-4.772 4.3 4.3 0 0 0-3.7-.463c-.075.022-.147.062-.249.1l.324.284q1.108.981 2.213 1.964a.264.264 0 0 1 .087.167q.025.809.025 1.609a.294.294 0 0 1-.067.177c-.249.279-.48.557-.727.826a.371.371 0 0 1-.2.114c-.5.067-1 .122-1.508.189a.329.329 0 0 1-.284-.095l-2.034-1.811c-.152-.127-.309-.266-.49-.426l-.149.973.047.769a.968.968 0 0 0 .04.11 9.91 9.91 0 0 0 .338 1.083 4.169 4.169 0 0 0 2.489 2.317 4.141 4.141 0 0 0 2.875.025.3.3 0 0 1 .229 0c.316.2.635.4.928.622.53.408 1.065.814 1.59 1.244l2.071-2.344q-.937-.894-1.785-1.882Z"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};

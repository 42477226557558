import * as React from "react";
import { SVGProps } from "react";

export const LoginLogoSVG = (props: SVGProps<SVGSVGElement>) => (
    <svg id="Group_8521" data-name="Group 8521" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 177.193 157.106">
        <defs>
            <clipPath id="clip-path">
                <rect id="Rectangle_2483" data-name="Rectangle 2483" width="177.192" height="157.106" fill="none" />
            </clipPath>
        </defs>
        <g id="Group_8519" data-name="Group 8519" clipPath="url(#clip-path)">
            <path
                id="Path_6894"
                data-name="Path 6894"
                d="M156.24,40.717A16.293,16.293,0,0,0,148.656,27a16.209,16.209,0,0,0-19.42-19.421,16.189,16.189,0,0,0-27.426,0A16.207,16.207,0,0,0,82.39,27a16.191,16.191,0,0,0,0,27.427A16.2,16.2,0,0,0,101.81,73.85a16.189,16.189,0,0,0,27.427,0A16.2,16.2,0,0,0,148.656,54.43a16.292,16.292,0,0,0,7.584-13.713"
                transform="translate(-26.928 0)"
                fill="#af7a00"
            />
            <path
                id="Path_6895"
                data-name="Path 6895"
                d="M106.028,85.567V99.215c-3.783,1.539-7.4.629-10.758-2.711-3.3-3.282-4.192-6.958-2.653-10.936Zm-14.308-.78c-1.9,4.508-.941,8.834,2.757,12.512a11.422,11.422,0,0,0,8.055,3.757,10.5,10.5,0,0,0,4.286-.957l.333-.147V84.444H91.864Z"
                transform="translate(-32.674 -30.397)"
                fill="#fff"
            />
            <path
                id="Path_6896"
                data-name="Path 6896"
                d="M141.7,105.033c-2.2,3.555-5.427,5.643-8.912,5.747-3.593.112-7.07-1.868-9.581-5.435l9.331-10.427Zm-19.914.216.241.362c2.677,4.017,6.482,6.3,10.48,6.3.106,0,.211,0,.314,0,4-.12,7.663-2.545,10.061-6.655l.206-.355L132.535,93.239Z"
                transform="translate(-43.839 -33.563)"
                fill="#fff"
            />
            <path
                id="Path_6897"
                data-name="Path 6897"
                d="M80.22,57.014a10.017,10.017,0,0,1,5.1-9.5l10.4,9.531L85.006,65.9c-2.771-1.365-4.638-4.8-4.786-8.882m5.273-10.863-.348.179A11.091,11.091,0,0,0,79.1,57.055c.17,4.659,2.436,8.589,5.774,10.015l.314.134L97.432,57.087Z"
                transform="translate(-28.468 -16.613)"
                fill="#fff"
            />
            <path
                id="Path_6898"
                data-name="Path 6898"
                d="M185.829,58.988a9.567,9.567,0,0,1-5.117,7.5L169.754,56.412c.746-.661,1.475-1.317,2.2-1.966,2.328-2.1,4.527-4.074,6.884-5.874a3.482,3.482,0,0,1,3.024.079,10.965,10.965,0,0,1,3.971,10.337m-3.276-11.22a4.422,4.422,0,0,0-4.4-.09c-2.393,1.829-4.608,3.822-6.953,5.933-.869.781-1.749,1.573-2.655,2.371l-.468.413,12.4,11.409.328-.131a10.586,10.586,0,0,0,6.134-8.526,12.078,12.078,0,0,0-4.389-11.38"
                transform="translate(-60.501 -16.967)"
                fill="#fff"
            />
            <path
                id="Path_6899"
                data-name="Path 6899"
                d="M161.545,99.385v-13.7h13.518a10.089,10.089,0,0,1-2.527,10.71c-3.273,3.325-6.871,4.3-10.991,2.988m14.32-14.821H160.42v15.623l.376.132a12.469,12.469,0,0,0,4.135.747,11.623,11.623,0,0,0,8.4-3.881A11.2,11.2,0,0,0,176,84.94Z"
                transform="translate(-57.745 -30.44)"
                fill="#fff"
            />
            <path
                id="Path_6900"
                data-name="Path 6900"
                d="M94.556,20.621c2.468-2.881,6.7-4.186,11.655-3.611V30.363H92.824c-1.029-3.783-.42-7.23,1.731-9.742M91.983,31.487h15.351V16.025l-.485-.067c-5.551-.773-10.344.66-13.148,3.932-2.485,2.9-3.137,6.877-1.839,11.2Z"
                transform="translate(-32.832 -5.671)"
                fill="#fff"
            />
            <path
                id="Path_6901"
                data-name="Path 6901"
                d="M123.339,9.271C125.1,5.6,129.187,4.326,132.5,4.356c4.224.047,7.805,2.073,9.229,5.191L132.667,20.24Zm19.694.478-.126-.315c-1.5-3.715-5.575-6.148-10.393-6.2h-.166c-4.725,0-8.617,2.254-10.184,5.911l-.138.319,10.643,12.513Z"
                transform="translate(-43.925 -1.163)"
                fill="#fff"
            />
            <path
                id="Path_6902"
                data-name="Path 6902"
                d="M161.481,18.28a3.176,3.176,0,0,1,2.03-1.719,9.725,9.725,0,0,1,8.361,2.682c2.529,2.553,3.672,6.57,3.165,11.076H161.363c0-.959-.011-1.906-.019-2.846-.028-3.176-.054-6.174.137-9.194m-1.241,12.6v.561h15.782l.067-.485c.7-5.052-.549-9.61-3.42-12.508a10.829,10.829,0,0,0-9.3-3c-1.18.152-2.936,1.605-3.008,2.762-.193,3.059-.168,6.078-.139,9.274.01,1.121.02,2.252.02,3.4"
                transform="translate(-57.666 -5.522)"
                fill="#fff"
            />
            <path
                id="Path_6903"
                data-name="Path 6903"
                d="M129.282,41.825C121.505,37.1,118.7,30.387,120.73,21.341c7.769,4.581,10.573,11.295,8.552,20.484m.1,1.36.648.372.178-.726c2.5-10.24-.721-17.926-9.574-22.843l-.639-.356-.178.712c-2.511,10.078.707,17.764,9.565,22.841"
                transform="translate(-42.83 -7.067)"
                fill="#fff"
            />
            <path
                id="Path_6904"
                data-name="Path 6904"
                d="M145.465,65.041c9.05-2.12,15.822.6,20.648,8.285-8.217,2.643-13.227.632-20.648-8.285m21.679,9.125.653-.225-.353-.593c-5.215-8.76-12.995-11.849-23.122-9.181l-.844.221.552.676c5.931,7.284,10.508,10.462,16.089,10.462a21.652,21.652,0,0,0,7.026-1.36"
                transform="translate(-51.647 -22.764)"
                fill="#fff"
            />
            <path
                id="Path_6905"
                data-name="Path 6905"
                d="M116.869,52.945c-7.749,2.723-13.91.21-20.373-8.32,8.993-2.08,15.843.717,20.373,8.32M95.4,43.738l-.8.208.49.668C100.278,51.7,105.444,55.1,111.258,55.1a18.513,18.513,0,0,0,6.633-1.34l.609-.232-.32-.568c-4.938-8.769-12.6-11.873-22.782-9.227"
                transform="translate(-34.051 -15.415)"
                fill="#fff"
            />
            <path
                id="Path_6906"
                data-name="Path 6906"
                d="M149.792,91.161c-7.786-4.568-10.636-11.279-8.7-20.479,7.652,4.117,9.837,9.265,8.7,20.479m-8.833-21.818-.639-.326-.163.7c-2.4,10.269.906,17.956,9.832,22.849l.735.4.094-.833c1.435-12.707-.962-18.245-9.859-22.791"
                transform="translate(-50.178 -24.844)"
                fill="#fff"
            />
            <path
                id="Path_6907"
                data-name="Path 6907"
                d="M117.615,65.143c-5.033,7.878-11.815,10.737-20.182,8.5,4.1-7.62,9.34-9.826,20.182-8.5m1.418-.156.441-.732-.847-.115c-12.156-1.65-18.05.864-22.513,9.6l-.309.6.649.191a22.285,22.285,0,0,0,6.331.983c6.584,0,12.031-3.525,16.249-10.532"
                transform="translate(-34.486 -22.917)"
                fill="#fff"
            />
            <path
                id="Path_6908"
                data-name="Path 6908"
                d="M120.721,90.752c-2.131-8.653.671-15.474,8.336-20.294,2.513,8.121.274,13.565-8.336,20.294m9.185-21.324-.221-.661-.6.355c-8.857,5.277-11.964,12.928-9.235,22.744l.222.8.66-.5c9.878-7.516,12.278-13.465,9.173-22.734"
                transform="translate(-42.797 -24.754)"
                fill="#fff"
            />
            <path
                id="Path_6909"
                data-name="Path 6909"
                d="M149.552,21.029c2.307,8.238-.321,14.685-8.226,20.186-2.341-8.585-.268-13.657,8.226-20.186m-9.094,21.245.216.732.634-.423c9.009-6,11.981-13.412,9.087-22.662l-.237-.759-.636.477c-9.636,7.22-11.923,12.933-9.064,22.635"
                transform="translate(-50.146 -6.898)"
                fill="#fff"
            />
            <path
                id="Path_6910"
                data-name="Path 6910"
                d="M166.771,44.751c-4.67,7.8-11.233,10.6-20.027,8.557,4.424-7.853,9.455-10,20.027-8.557M145.4,53.413l-.341.631.694.179a24.663,24.663,0,0,0,6.164.84c6.873,0,12.322-3.506,16.243-10.475l.4-.71-.806-.121c-11.865-1.775-17.506.659-22.353,9.656"
                transform="translate(-52.217 -15.556)"
                fill="#fff"
            />
            <path
                id="Path_6911"
                data-name="Path 6911"
                d="M5.75,169.251H16.2l-5.262-12.856Zm16.389,8.759a1,1,0,0,1-.338.789,1.151,1.151,0,0,1-.789.3,1.079,1.079,0,0,1-.658-.207,1.33,1.33,0,0,1-.433-.544l-2.819-6.88H4.886L2.1,178.348a1.044,1.044,0,0,1-1.014.751,1.074,1.074,0,0,1-.79-.264,1.091,1.091,0,0,1-.3-.714v-.112a1.108,1.108,0,0,1,.037-.339L9.96,153.388a1.118,1.118,0,0,1,1.129-.752,1.089,1.089,0,0,1,.658.207,1.349,1.349,0,0,1,.431.545l9.886,24.17a2.118,2.118,0,0,1,.075.452"
                transform="translate(0 -54.943)"
                fill="#00060c"
            />
            <path
                id="Path_6912"
                data-name="Path 6912"
                d="M65.979,177.208A1.038,1.038,0,0,1,66.3,178a1.093,1.093,0,0,1-1.128,1.128H51.751A1.093,1.093,0,0,1,50.623,178V153.94a1.064,1.064,0,0,1,.339-.808,1.208,1.208,0,0,1,.864-.32,1.161,1.161,0,0,1,.828.32,1.063,1.063,0,0,1,.337.808v22.967H65.17a1.131,1.131,0,0,1,.808.3"
                transform="translate(-18.222 -55.007)"
                fill="#00060c"
            />
            <path
                id="Path_6913"
                data-name="Path 6913"
                d="M106.031,177.208a1.037,1.037,0,0,1,.321.789,1.1,1.1,0,0,1-1.129,1.128H91.8A1.094,1.094,0,0,1,90.675,178V153.94a1.064,1.064,0,0,1,.339-.808,1.208,1.208,0,0,1,.865-.32,1.161,1.161,0,0,1,.828.32,1.065,1.065,0,0,1,.337.808v22.967h12.179a1.131,1.131,0,0,1,.808.3"
                transform="translate(-32.64 -55.007)"
                fill="#00060c"
            />
            <path
                id="Path_6914"
                data-name="Path 6914"
                d="M132.721,178.787a1.128,1.128,0,0,1-.828.339,1.1,1.1,0,0,1-1.166-1.165V153.978a1.112,1.112,0,0,1,.339-.847,1.21,1.21,0,0,1,.864-.319,1.156,1.156,0,0,1,1.166,1.166V177.96a1.273,1.273,0,0,1-.376.827"
                transform="translate(-47.057 -55.007)"
                fill="#00060c"
            />
            <path
                id="Path_6915"
                data-name="Path 6915"
                d="M155.307,178.387a13.422,13.422,0,0,1-4.041-2.819,1.133,1.133,0,0,1-.451-.9,1.179,1.179,0,0,1,.357-.827,1.092,1.092,0,0,1,.808-.376,1.154,1.154,0,0,1,.827.376,9.483,9.483,0,0,0,7.328,3.233,7.537,7.537,0,0,0,4.494-1.277,3.936,3.936,0,0,0,1.784-3.345,4.091,4.091,0,0,0-.884-2.688,6.578,6.578,0,0,0-2.312-1.748,30.7,30.7,0,0,0-3.646-1.391,22.255,22.255,0,0,1-4.041-1.579,7.41,7.41,0,0,1-2.65-2.311,6.234,6.234,0,0,1-1.015-3.665,6.029,6.029,0,0,1,1.073-3.534,7.183,7.183,0,0,1,3.006-2.424,10.619,10.619,0,0,1,4.454-.884A11.947,11.947,0,0,1,164.7,153a7.72,7.72,0,0,1,3.213,2.2,1.819,1.819,0,0,1,.564,1.09,1.057,1.057,0,0,1-.376.771,1.177,1.177,0,0,1-.827.357.867.867,0,0,1-.639-.264,7.427,7.427,0,0,0-2.706-1.954,8.624,8.624,0,0,0-3.534-.752,7.582,7.582,0,0,0-4.473,1.222,4.138,4.138,0,0,0-.075,6.672,17.664,17.664,0,0,0,4.736,2.142,28.641,28.641,0,0,1,4.361,1.617,7.161,7.161,0,0,1,2.762,2.349,6.817,6.817,0,0,1,1.072,4,5.867,5.867,0,0,1-1.109,3.477,7.5,7.5,0,0,1-3.082,2.462,10.692,10.692,0,0,1-4.493.9,11.967,11.967,0,0,1-4.791-.9"
                transform="translate(-54.288 -54.795)"
                fill="#00060c"
            />
            <path
                id="Path_6916"
                data-name="Path 6916"
                d="M212.762,159.913a9.912,9.912,0,0,0-17.779,0,13.816,13.816,0,0,0,0,11.69,9.724,9.724,0,0,0,3.608,4,9.911,9.911,0,0,0,14.171-4,13.816,13.816,0,0,0,0-11.69m2.086,12.8a12.357,12.357,0,0,1-21.951,0,15.84,15.84,0,0,1,0-13.946,12.016,12.016,0,0,1,4.472-4.812,13.1,13.1,0,0,1,13.006,0,12.028,12.028,0,0,1,4.473,4.812,15.845,15.845,0,0,1,0,13.946"
                transform="translate(-68.854 -54.795)"
                fill="#00060c"
            />
            <path
                id="Path_6917"
                data-name="Path 6917"
                d="M265.15,153.113a1.056,1.056,0,0,1,.282.752v24.056a1.129,1.129,0,0,1-1.166,1.2,1.19,1.19,0,0,1-.506-.112,1.34,1.34,0,0,1-.395-.264l-16.126-21.727V178.11a.948.948,0,0,1-.321.714,1.034,1.034,0,0,1-.732.3,1.02,1.02,0,0,1-.752-.3.971.971,0,0,1-.3-.714V153.939a1.062,1.062,0,0,1,1.09-1.126,1.042,1.042,0,0,1,.9.413l16.2,21.838v-21.2a1.026,1.026,0,0,1,1.052-1.052,1.007,1.007,0,0,1,.771.3"
                transform="translate(-88.239 -55.007)"
                fill="#00060c"
            />
            <path
                id="Path_6918"
                data-name="Path 6918"
                d="M77.613,221.351a1.012,1.012,0,0,1,.308.759v13.268a1.13,1.13,0,0,1-.33.771,1.07,1.07,0,0,1-.791.308,1.055,1.055,0,0,1-.76-.308,1.029,1.029,0,0,1-.319-.771v-5.7H67.91v5.7a1.013,1.013,0,0,1-.33.771,1.092,1.092,0,0,1-.769.308,1.034,1.034,0,0,1-.781-.308,1.064,1.064,0,0,1-.3-.771V222.11a1.017,1.017,0,0,1,.308-.748,1.071,1.071,0,0,1,.793-.309,1.052,1.052,0,0,1,.758.309,1,1,0,0,1,.319.748v5.545h7.811V222.11a.987.987,0,0,1,.33-.748,1.159,1.159,0,0,1,1.562-.012"
                transform="translate(-23.661 -79.571)"
                fill="#af7a00"
            />
            <path
                id="Path_6919"
                data-name="Path 6919"
                d="M108.758,225.65a5.157,5.157,0,0,0-1.838-2.09,4.823,4.823,0,0,0-2.673-.76,4.88,4.88,0,0,0-2.673.749,5.068,5.068,0,0,0-1.838,2.079,7.112,7.112,0,0,0,0,5.984,5.146,5.146,0,0,0,1.838,2.091,5.09,5.09,0,0,0,5.346,0,5.156,5.156,0,0,0,1.838-2.091,7.065,7.065,0,0,0,0-5.963m1.969,7.009a7.31,7.31,0,0,1-2.651,2.849,7.537,7.537,0,0,1-7.658,0,7.306,7.306,0,0,1-2.651-2.849,8.952,8.952,0,0,1,0-8.054,7.306,7.306,0,0,1,2.651-2.849,7.537,7.537,0,0,1,7.658,0,7.311,7.311,0,0,1,2.651,2.849,8.951,8.951,0,0,1,0,8.054"
                transform="translate(-34.848 -79.448)"
                fill="#af7a00"
            />
            <path
                id="Path_6920"
                data-name="Path 6920"
                d="M146.242,221.338a.986.986,0,0,1,.308.738v13.312a1.034,1.034,0,0,1-1.079,1.056,1.088,1.088,0,0,1-.791-.3,1,1,0,0,1-.308-.758V225.2l-4.269,6.469a1.3,1.3,0,0,1-.385.33.79.79,0,0,1-.474.109.744.744,0,0,1-.451-.109,1.278,1.278,0,0,1-.385-.33L134.117,225v10.387a1.076,1.076,0,0,1-.274.758.954.954,0,0,1-.737.3.928.928,0,0,1-.716-.3,1.071,1.071,0,0,1-.275-.758V222.076a1.071,1.071,0,0,1,1.077-1.057,1.17,1.17,0,0,1,.924.485l5.215,7.9,5.282-7.9a1.052,1.052,0,0,1,.879-.485,1.134,1.134,0,0,1,.749.319"
                transform="translate(-47.557 -79.558)"
                fill="#af7a00"
            />
            <path
                id="Path_6921"
                data-name="Path 6921"
                d="M177.583,234.741a1.017,1.017,0,0,1,.3.726.93.93,0,0,1-.3.715,1.07,1.07,0,0,1-.76.275h-7.658a1.052,1.052,0,0,1-1.055-1.055v-13.29a1.052,1.052,0,0,1,1.055-1.056h7.658a1.045,1.045,0,0,1,.76.286.994.994,0,0,1,.3.748.932.932,0,0,1-.3.715,1.073,1.073,0,0,1-.76.275h-6.535v4.489h5.545a1.045,1.045,0,0,1,.76.286.962.962,0,0,1,.3.726.923.923,0,0,1-.3.726,1.1,1.1,0,0,1-.76.263h-5.545v4.863h6.535a1.009,1.009,0,0,1,.76.308"
                transform="translate(-60.513 -79.571)"
                fill="#af7a00"
            />
            <path
                id="Path_6922"
                data-name="Path 6922"
                d="M196.709,236.1a7.233,7.233,0,0,1-2.355-1.464,1.168,1.168,0,0,1-.461-.9,1,1,0,0,1,.308-.715.976.976,0,0,1,.726-.319.911.911,0,0,1,.638.241,6.085,6.085,0,0,0,1.815,1.2,5.538,5.538,0,0,0,2.124.385,4.123,4.123,0,0,0,2.342-.638,1.882,1.882,0,0,0,.958-1.606,2.065,2.065,0,0,0-.958-1.771A8.552,8.552,0,0,0,199,229.467a7.572,7.572,0,0,1-3.488-1.518,3.661,3.661,0,0,1-1.222-2.926,3.882,3.882,0,0,1,.682-2.277,4.361,4.361,0,0,1,1.893-1.507,6.753,6.753,0,0,1,2.728-.528,7.489,7.489,0,0,1,2.574.44,5.433,5.433,0,0,1,1.959,1.167,1.258,1.258,0,0,1,.506.945.986.986,0,0,1-.3.7.954.954,0,0,1-.715.307.73.73,0,0,1-.506-.176,4.591,4.591,0,0,0-1.584-.968,5.307,5.307,0,0,0-1.937-.4,4.032,4.032,0,0,0-2.343.605,1.948,1.948,0,0,0,0,3.246,8.146,8.146,0,0,0,2.584.967,13.787,13.787,0,0,1,2.806.881,3.971,3.971,0,0,1,1.685,1.407,4.232,4.232,0,0,1,.593,2.355,3.776,3.776,0,0,1-.715,2.266A4.713,4.713,0,0,1,202.253,236a6.585,6.585,0,0,1-2.706.55,8.245,8.245,0,0,1-2.839-.452"
                transform="translate(-69.794 -79.448)"
                fill="#af7a00"
            />
        </g>
    </svg>
);

import * as React from "react";
import { SVGProps } from "react";
import { theme } from "../../Styles";
import { Box } from "../../Components";
interface Props extends SVGProps<SVGSVGElement> {
    showblankcircle?: boolean;
    colour?: string;
}
export const SalesProgressSVG: React.FC<Props> = (props: Props) => {
    const { showblankcircle, colour, ...rest } = props;

    const setColour = () => {
        if (colour) {
            return colour;
        } else {
            return "#b88e00";
        }
    };

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={68} height={68} {...rest}>
            <defs>
                <filter id="a" x={0} y={0} width={68} height={68} filterUnits="userSpaceOnUse">
                    <feOffset dy={3} />
                    <feGaussianBlur stdDeviation={3} result="blur" />
                    <feFlood floodOpacity={0.251} />
                    <feComposite operator="in" in2="blur" />
                    <feComposite in="SourceGraphic" />
                </filter>
            </defs>
            <g data-name="Group 8266">
                <g data-name="Group 8264">
                    {showblankcircle && (
                        <g data-name="Ellipse 209" transform="translate(9 6)" fill={theme.palette.primary.main} stroke={theme.palette.primary.main} strokeWidth={2.5}>
                            <circle cx={25} cy={25} r={30} stroke="none" />
                            <circle cx={25} cy={25} r={27.75} fill="none" />
                        </g>
                    )}
                    <g filter="url(#a)">
                        <g data-name="Ellipse 209" transform="translate(9 6)" fill="#806302" stroke={setColour()} /* "#b88e00" */ strokeWidth={2.5}>
                            <circle cx={25} cy={25} r={25} stroke="none" />
                            <circle cx={25} cy={25} r={23.75} fill="none" />
                        </g>
                    </g>
                    <path
                        data-name="Path 3682"
                        d="M30.312 29.457a5.189 5.189 0 0 0 5.666 1.167l.435.294.817-.113-.759 1 .935.936 1.73-.118-.332 1.315.8.833 1.42-.063-.263 1.1 1.022 1.016 1.107-.472.594.476-.29.657.07.051 3.471.206.265-1.256-8.464-8.6a4.884 4.884 0 0 0-1.147-5.5 5 5 0 1 0-7.077 7.063Zm.895-4a1.593 1.593 0 1 1 2.291.043 1.6 1.6 0 0 1-2.291-.045Z"
                        fill="#fff" /* "#b88e00" */
                        fillRule="evenodd"
                    />
                    <g data-name="Group 5553">
                        <path
                            data-name="Path 3683"
                            d="M29.428 30.339a6.465 6.465 0 0 1-1.915-4.481 6.273 6.273 0 0 1 .19-1.555 5.135 5.135 0 0 0-.915 4.847 5.189 5.189 0 0 0 4.5 3.634l.249.459.778.275-1.131.544.4 1.258 1.592.688-.9 1.018.333 1.106 1.29.6-.738.855.439 1.375 1.2.088.309.7-.559.449.038.075 2.99 1.776.812-.993-3.367-10.876a6.253 6.253 0 0 1-.989.081 6.471 6.471 0 0 1-4.606-1.923Z"
                            fill="#fff" /* "#b88e00" */
                        />
                    </g>
                    <g data-name="Group 5554">
                        <path
                            data-name="Path 3684"
                            d="M27.476 18a5.445 5.445 0 0 0-1.9 10.558c-.022-.17-.044-.339-.06-.516-.017-.2-.015-.388-.014-.578a4.474 4.474 0 1 1 6.465-3.987 4.24 4.24 0 0 1-.1.868.525.525 0 0 0 .456.441.46.46 0 0 0 .524-.287 5.343 5.343 0 0 0 .1-1.021A5.482 5.482 0 0 0 27.476 18Z"
                            fill="#fff" /* "#b88e00" */
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};

import styled from "@emotion/styled";
import { Box } from "./General";
import { theme } from "../Styles";
import React from "react";
import { container } from "tsyringe";
import { DomainStore } from "../Stores/Domain/DomainStore";
import { Label, RelayCommand } from "../Application";
import { getHistory, isNullOrEmpty } from "@shoothill/core";
import { RightChevronSVG } from "../Assets/Icons/RightChevronSVG";
import { RightBeak } from "../Styles/BaseStyles";

const StyledBlock = styled(Box)`
    display: flex;
    transition: all 0.3s ease;
    gap: 0;
    position: relative;
    cursor: pointer;
`;
const IconContainer = styled(Box)`
    margin-top: 0;
    display: flex;
    align-items: center;
    align-items: center;
    height: 60px;
    width: 60px;
    justify-content: center;

    svg {
        height: 100% !important;
        width: 100% !important;
    }
`;
interface BlockProps {
    icon?: JSX.Element;
    title: string;
    image?: string;
    description?: string;
    subTitle?: string;
    lastUpdated?: string;
    path?: string;
    applyTransform?: boolean;
    view?: "homeView" | "panelView" | "snagView";
    style?: any;
    onClick?: () => void;
}
export const MenuBlock: React.FC<BlockProps> = (props) => {
    const domainStore = container.resolve(DomainStore);
    const getStyles = () => {
        if (props.applyTransform) {
            return { transform: "scale(1)", transformOrigin: "10px 8px" };
        } else {
            return {};
        }
    };
    const gotoPageCommand = new RelayCommand(() => {
        if (props.path) {
            getHistory().push(props.path);
            domainStore.setSideBarClosed(false);
        }
        if (props.onClick) {
            props.onClick();
            domainStore.setSideBarClosed(true);
        }
    });
    return (
        <StyledBlock id={"block"} onClick={gotoPageCommand.execute} style={props.style!}>
            <Box flexBox style={{ gap: "20px" }} alignItems={"center"} gap={"20px"}>
                {props.icon && <IconContainer style={getStyles()}>{props.icon}</IconContainer>}
                <Box flexBox flexDirection={"column"} justifyContent={"center"} style={{ gap: 0, cursor: "pointer" }} mr={"18px"}>
                    <Label
                        fontStyle={"h4"}
                        style={{
                            font: "normal normal 300 18px/22px Lato",
                            letterSpacing: "0px",
                            color: "#325083",
                            textTransform: "uppercase",
                            margin: "0",
                        }}
                        margin={0}
                    >
                        {props.title}
                    </Label>
                    {/* <Label
                        fontStyle={"h4"}
                        style={{ letterSpacing: "0px", marginTop: "1px", color: "#FFFFFF", textTransform: "uppercase", fontSize: "12px", lineHeight: "18px", fontWeight: 300 }}
                    >
                        {props.subTitle}
                    </Label> */}
                    {!isNullOrEmpty(props.subTitle) && (
                        <Label
                            fontStyle={"h6"}
                            margin={0}
                            style={{ letterSpacing: "0px", fontSize: "12px", lineHeight: "14px", color: "#FFFFFF", textTransform: "uppercase", margin: "4px 0 0" }}
                        >
                            {props.subTitle}
                        </Label>
                    )}
                </Box>
            </Box>
            {/* <Box style={{ position: "absolute", right: 0 }}>
                <RightChevronSVG style={{ position: "absolute", right: "-23px", zIndex: 2, top: "2px", transform: "scale(1.1)" }} />
                <RightBeak style={{ position: "absolute" }} size={"22px"} />
            </Box> */}
        </StyledBlock>
    );
};
MenuBlock.defaultProps = {
    applyTransform: true,
    view: "panelView",
};

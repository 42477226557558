import React from "react";
import { Icon, IIconStyles, IStackStyles, IStackTokens, mergeStyleSets, TooltipHost } from "@fluentui/react";
import { isEmptyOrWhitespace, isNullOrUndefined } from "@shoothill/core";
import { PropsWithChildren } from "react";

import { getThemeColorOption, getThemePaletteOption, getThemeShapeOption, getThemeSizeOption, pxToRem } from "../../../Application/Helpers";
import { theme } from "../../AppTheme";
import { EditTextBase, IEditTextBaseProps } from "../../../Components/StyleFrameworks/FluentUI/Primitives/Active/EditTextBase";
import { observer } from "mobx-react-lite";
import { ThemedText } from "./ThemedText";
import { Box, Show } from "../../../Components";
import { ExclamationIconSVG } from "../../../Assets/Icons/ExclamationIconSVG";
import { TickIcon } from "../../../Assets/Icons/TickIconSVG";
import { nanoid } from "nanoid";

/**
 * A styled edit text control. The styling can be adjusted using the size and paletteColor props.
 */
export const ThemedEditText = observer((props: PropsWithChildren<IEditTextBaseProps>) => {
    const size = getThemeSizeOption(props.size);
    const shape = getThemeShapeOption(props.shape);
    const haveError = !isEmptyOrWhitespace(props.validationMessage?.() as string);
    const isMultiLine = !isNullOrUndefined(props.isMultiline) ? props.isMultiline : false;
    const labelColor = getThemeColorOption(props.labelColor);

    // Note. When merging styles, do this after the props spreader, otherwise props.styles will overwrite
    // base styles instead of being merged.
    return (
        <EditTextBase
            {...props}
            onRenderLabel={(isInError) => {
                if (isInError) {
                    return (
                        <Box display={"flex"}>
                            <ThemedText pb={"10px"} mr={1}>
                                {props.displayName ? <ThemedText color={"error"}>{props.displayName}</ThemedText> : <ThemedText color={"error"}>Error</ThemedText>}
                            </ThemedText>
                            <TooltipHost
                                tooltipProps={{
                                    calloutProps: {
                                        gapSpace: 0,
                                        styles: {
                                            beak: { background: theme.palette.error.main },
                                            beakCurtain: { background: theme.palette.error.main },
                                            calloutMain: { background: theme.palette.error.main },
                                        },
                                    },
                                }}
                                content={
                                    <ThemedText fontStyle={"h7"} color={"white"}>
                                        {props.validationMessage?.() as string}
                                    </ThemedText>
                                }
                                id={nanoid()}
                                styles={{ root: { display: "inline-block" } }}
                            >
                                <ExclamationIconSVG color={"white"} backgroundcolor={theme.palette.error.main} />
                            </TooltipHost>
                        </Box>
                    );
                }
                return (
                    <Box display={"flex"}>
                        <ThemedText pb={"10px"} mr={1}>
                            {props.displayName}
                        </ThemedText>
                        <Show if={props.validationMessage?.() !== undefined && props.validationMessage() === ""}>
                            <TickIcon />
                        </Show>
                    </Box>
                );
            }}
            styles={mergeStyleSets(
                // The first style set concerns the border of the text field. Setting the
                // border color on hover and focus states is messy, so I've extracted those
                // style properties here.
                {
                    fieldGroup: {
                        borderColor: haveError ? theme.palette.error.light : theme.palette.field.light,
                        borderRadius: shape,
                        "::after": {
                            borderRadius: shape,
                            borderColor: theme.palette.common.focus,
                        },
                        ":hover": {
                            borderColor: theme.palette.field.main,
                        },
                        ":hover::after": {
                            borderColor: haveError ? theme.palette.field.main : theme.palette.common.focus,
                        },
                    },
                },
                {
                    root: {},
                    description: {},
                    errorMessage: {
                        color: theme.palette.error.text,
                        fontFamily: theme.fontStyles.error.fontFamily ?? theme.defaultFontStyle.fontFamily,
                        fontSize: theme.fontStyles.error.fontSize ?? theme.defaultFontStyle.fontSize,
                        fontWeight: theme.fontStyles.error.fontWeight ?? theme.defaultFontStyle.fontWeight,
                        letterSpacing: theme.fontStyles.error.letterSpacing ?? theme.defaultFontStyle.letterSpacing,
                        lineHeight: theme.fontStyles.error.lineHeight ?? theme.defaultFontStyle.lineHeight,
                        textTransform: theme.fontStyles.error.textTransform ?? theme.defaultFontStyle.textTransform,
                    },
                    field: {
                        color: theme.palette.field.text,
                        fontFamily: theme.fontStyles.field.fontFamily ?? theme.defaultFontStyle.fontFamily,
                        fontSize: theme.fontStyles.field.fontSize ?? theme.defaultFontStyle.fontSize,
                        fontWeight: theme.fontStyles.field.fontWeight ?? theme.defaultFontStyle.fontWeight,
                        letterSpacing: theme.fontStyles.field.letterSpacing ?? theme.defaultFontStyle.letterSpacing,
                        lineHeight: theme.fontStyles.field.lineHeight ?? theme.defaultFontStyle.lineHeight,
                        textTransform: theme.fontStyles.field.textTransform ?? theme.defaultFontStyle.textTransform,
                        padding: `0 ${pxToRem(theme.space[1])}`,
                        borderRadius: shape,
                    },
                    fieldGroup: {
                        borderColor: haveError ? theme.palette.error.light : theme.palette.field.light,
                        borderRadius: shape,
                        height: isMultiLine ? "auto" : size,
                    },
                    icon: {},
                    prefix: {
                        padding: 0,
                    },
                    suffix: {
                        padding: 0,
                    },
                    revealButton: {
                        // Fixes issues with rounded edit controls.
                        borderRadius: shape,
                        right: 0,
                        height: "99%",
                        width: props.shape === "round" ? size : "auto",
                        ":hover": {
                            cursor: "pointer",
                        },
                    },
                    revealIcon: {},
                    revealSpan: {
                        justifyContent: "center",
                    },
                    subComponentStyles: {
                        label: {
                            root: {
                                color: labelColor,
                                fontFamily: theme.fontStyles.label.fontFamily ?? theme.defaultFontStyle.fontFamily,
                                fontSize: theme.fontStyles.label.fontSize ?? theme.defaultFontStyle.fontSize,
                                fontWeight: theme.fontStyles.label.fontWeight ?? theme.defaultFontStyle.fontWeight,
                                letterSpacing: theme.fontStyles.label.letterSpacing ?? theme.defaultFontStyle.letterSpacing,
                                lineHeight: theme.fontStyles.label.lineHeight ?? theme.defaultFontStyle.lineHeight,
                                textTransform: theme.fontStyles.label.textTransform ?? theme.defaultFontStyle.textTransform,
                            },
                        },
                    },
                    wrapper: {},
                },
                props.styles,
            )}
        />
    );
});

// #region Directly-styled buttons

export const ThemedDefaultEditText = observer((props: PropsWithChildren<IEditTextBaseProps>) => {
    const haveError = !isEmptyOrWhitespace(props.validationMessage?.() as string);
    const isMultiLine = !isNullOrUndefined(props.isMultiline) ? props.isMultiline : false;

    // Note. When merging styles, do this after the props spreader, otherwise props.styles will overwrite
    // base styles instead of being merged.
    return (
        <EditTextBase
            {...props}
            styles={mergeStyleSets(
                // The first style set concerns the border of the text field. Setting the
                // border color on hover and focus states is messy, so I've extracted those
                // style properties here.
                {
                    fieldGroup: {
                        borderColor: haveError ? theme.palette.error.light : theme.palette.field.light,
                        "::after": {
                            borderColor: theme.palette.common.focus,
                        },
                        ":hover": {
                            borderColor: theme.palette.field.main,
                        },
                        ":hover::after": {
                            borderColor: haveError ? theme.palette.field.main : theme.palette.common.focus,
                        },
                    },
                },
                {
                    root: {},
                    description: {},
                    errorMessage: {
                        color: theme.palette.error.text,
                        fontFamily: theme.fontStyles.error.fontFamily ?? theme.defaultFontStyle.fontFamily,
                        fontSize: theme.fontStyles.error.fontSize ?? theme.defaultFontStyle.fontSize,
                        fontWeight: theme.fontStyles.error.fontWeight ?? theme.defaultFontStyle.fontWeight,
                        letterSpacing: theme.fontStyles.error.letterSpacing ?? theme.defaultFontStyle.letterSpacing,
                        lineHeight: theme.fontStyles.error.lineHeight ?? theme.defaultFontStyle.lineHeight,
                        textTransform: theme.fontStyles.error.textTransform ?? theme.defaultFontStyle.textTransform,
                    },
                    field: {
                        color: theme.palette.field.text,
                        fontFamily: theme.fontStyles.field.fontFamily ?? theme.defaultFontStyle.fontFamily,
                        fontSize: theme.fontStyles.field.fontSize ?? theme.defaultFontStyle.fontSize,
                        fontWeight: theme.fontStyles.field.fontWeight ?? theme.defaultFontStyle.fontWeight,
                        letterSpacing: theme.fontStyles.field.letterSpacing ?? theme.defaultFontStyle.letterSpacing,
                        lineHeight: theme.fontStyles.field.lineHeight ?? theme.defaultFontStyle.lineHeight,
                        textTransform: theme.fontStyles.field.textTransform ?? theme.defaultFontStyle.textTransform,
                        padding: `0 ${pxToRem(10)}`,
                    },
                    fieldGroup: {
                        height: isMultiLine ? "auto" : theme.sizes.larger,
                    },
                    icon: {},
                    prefix: {},
                    revealButton: {
                        height: "99%",
                        ":hover": {
                            cursor: "pointer",
                        },
                    },
                    revealIcon: {},
                    revealSpan: {},
                    subComponentStyles: {
                        label: {
                            root: {
                                color: theme.palette.common.default,
                                fontFamily: theme.fontStyles.label.fontFamily ?? theme.defaultFontStyle.fontFamily,
                                fontSize: theme.fontStyles.label.fontSize ?? theme.defaultFontStyle.fontSize,
                                fontWeight: theme.fontStyles.label.fontWeight ?? theme.defaultFontStyle.fontWeight,
                                letterSpacing: theme.fontStyles.label.letterSpacing ?? theme.defaultFontStyle.letterSpacing,
                                lineHeight: theme.fontStyles.label.lineHeight ?? theme.defaultFontStyle.lineHeight,
                                textTransform: theme.fontStyles.label.textTransform ?? theme.defaultFontStyle.textTransform,
                            },
                        },
                    },
                    suffix: {},
                    wrapper: {},
                },
                props.styles,
            )}
        />
    );
});

// #endregion Directly-styled buttons

import { mergeStyleSets } from "@fluentui/react";
import { PropsWithChildren } from "react";

import { theme } from "Styles/AppTheme";
import { HeaderBase, IHeaderProps } from "./HeaderBase";
import logo from "Assets/allisonHomesMainLogo.png";
import { pxToRem } from "../../Application";

export const HeaderStyles = (props: PropsWithChildren<IHeaderProps>) => {
    return (
        <HeaderBase
            {...props}
            styles={mergeStyleSets(
                {
                    root: {
                        boxShadow: `0px 3px 6px #00000040`,
                        backgroundColor: "white",
                        display: "flex",
                        justifyContent: "center",
                        minHeight: "50px",
                        zIndex: 1000,
                        position: "fixed",
                        top: 0,
                        left: 0,
                        width: "100%",
                        background: "#FFFFFF 0% 0% no-repeat padding-box",
                    },
                    content: {
                        display: "flex",
                        flex: 1,
                        justifyContent: "space-between",
                        margin: `0 1rem`,
                        maxWidth: "1200px",
                        alignItems: "center",
                    },
                    options: {
                        display: "flex",
                    },
                    option: {
                        transition: "background-color 0.5s",
                        color: theme.palette.field.text,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        "& > div": {
                            alignItems: "center",
                            display: "flex",
                            color: "#4A4A4A",
                            textTransform: "uppercase",
                            lineHeight: "22px",
                            fontWeight: "300",
                            margin: `0 ${pxToRem(20)}`,
                            fontSize: theme.fontStyles.headerOptionLabel.fontSize,
                            letterSpacing: theme.fontStyles.headerOptionLabel.letterSpacing,
                            borderBottom: "2px solid transparent",
                        },
                        "&:hover": {
                            cursor: "pointer",
                        },
                    },
                    optionActive: {
                        // "&&": {
                        //     color: theme.palette.common.white,
                        //     backgroundColor: theme.palette.common.secondary,
                        //     transition: "background-color 0.5s",
                        // },
                        "& > div": {
                            borderColor: theme.palette.common.secondary,
                        },
                    },
                    logoOption: {
                        backgroundImage: `url(${logo})`,
                        backgroundPosition: "left center",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                        flex: 1,
                        //margin: `${pxToRem(17.5)} ${pxToRem(30)} ${pxToRem(17.5)} 0`,
                        maxWidth: pxToRem(110),
                        width: "58px",
                        height: "58px",
                        "&:hover": {
                            cursor: "pointer",
                        },
                        "@media (max-width: 960px)": {
                            width: "42px",
                            height: "42px",
                        },
                    },
                },
                props.styles,
            )}
        />
    );
};

import { FieldType, ViewModelBase, KeyValuePair, CoreStoreInstance } from "@shoothill/core";
import { APIClient, ICommand, ICommandAsync, RelayCommand, RelayCommandAsync, IKeyState } from "Application";
import { action, makeObservable, observable, runInAction } from "mobx";
import { AppUrls } from "AppUrls";
import { Logger } from "../../../../../index";
import { GetUserByIdEndpoint } from "../../Endpoints/PortalUsers/GetUserByIdEndpoint";
import { CheckUserEndpoint } from "../../Endpoints/PortalUsers/CheckUserEndpoint";
import { SaveUserEndpoint } from "../../Endpoints/PortalUsers/SaveUserEndpoint";
import { GETPlotEndpoint } from "../../Endpoints/PortalUsers/GETPlotEndpoint";
import { POSTUserPlotEndpoint } from "../../Endpoints/PortalUsers/POSTUserPlotEndpoint";
import { GETDevelopemntsEndpoint } from "../../Endpoints/PortalUsers/GETDevelopmentsEndpoint";
import { PortalUsersModel, PortalUsersModelValidator } from "../PortalUsersModel";
import PortalUsersViewModel from "../PortalUsersViewModel";
import { ForgotPasswordEndpoint } from "Views/Login/SignIn/ForgotPassword/Endpoints/ForgotPasswordEndpoint";

export default class PortalUserDetailsViewModel extends ViewModelBase<PortalUsersModel> {
    public useData = new PortalUsersViewModel();
    public apiClient = new APIClient();
    public resetLoginAttemptsError = "";
    public isTableLoading: boolean = false;
    public developmentOptions: KeyValuePair[] = [{ key: null, text: "Please Select Developmet..." }];
    public plotOptions: KeyValuePair[] = [];
    public editMail = "";
    public firstName = "";
    public lastName = "";
    public newUser = true;
    public userId: string | null = null;
    private forgotPasswordEndpoint = new ForgotPasswordEndpoint();
    constructor() {
        super(new PortalUsersModel(null));
        this.setValidator(new PortalUsersModelValidator());
        makeObservable(this, {
            resetLoginAttemptsError: observable,
            isTableLoading: observable,
            developmentOptions: observable,
            plotOptions: observable,
            editMail: observable,
            set: action,
            loadUserAsync: action,
            firstName: observable,
            lastName: observable,
            resetFailedLoginAttempts: action,
        });
        this.apiClient.sendAsync(new GETDevelopemntsEndpoint(this));
    }

    public updateFirstNameCommand: ICommand = new RelayCommand(async (value: string, keyState: IKeyState) => {
        await this.updateField("firstName", value, keyState);
    });
    public updateLastNameCommand: ICommand = new RelayCommand(async (value: string, keyState: IKeyState) => {
        await this.updateField("lastName", value, keyState);
    });
    public updateEmailAddressCommand: ICommand = new RelayCommand((value: string) => {
        this.updateField("emailAddress", value);
        this.checkDuplicateEmail("emailAddress", value);
    });
    public updateSelectRolesCommand: ICommand = new RelayCommand((value: string[]) => {
        this.updateField("userRoles", value);
    });
    public updateDevelopmentIdCommand = new RelayCommand((value: string) => {
        this.setValue("developmentId", value);
        /*if(this.model.id) {
            this.accountStore.CoinsDevelopmentId = value;
        }*/
        this.apiClient.sendAsync(new GETPlotEndpoint(value, this));
    });
    public updateCoinsPlotIdCommand = new RelayCommand((value: string) => {
        this.setValue("plotId", value);
        /*if(this.model.id) {
            this.accountStore.CoinsPlotId = value;
        }*/
    });
    private updateField(fieldName: keyof FieldType<PortalUsersModel>, value: any, keyState?: IKeyState) {
        this.setValue(fieldName, value);
        this.isFieldValid(fieldName);
    }
    private checkDuplicateEmail(fieldName: keyof FieldType<PortalUsersModel>, value: any, keyState?: IKeyState) {
        const emails = this.useData.getUsers.filter((el) => el.emailAddress === value);
        if (((this.editMail !== "" && this.editMail !== this.getValue(fieldName)) || (this.editMail == "" && this.newUser)) && emails.length > 0) {
            this.setError(fieldName, "This email address is already in use,please use a different email address");
            return false;
        }
        return true;
    }

    private checkValidation() {
        const isValid = this.isModelValid();
        const checkEmail = this.checkDuplicateEmail("emailAddress", this.getValue("emailAddress"));
        return isValid && checkEmail;
    }

    public forgotPasswordCommand: ICommandAsync = new RelayCommandAsync(async () => {
        await this.apiClient.sendAsync(this.forgotPasswordEndpoint, this.model);
        if (this.apiClient.IsRequestSuccessful) {
            this.history.push("admin/dashboard/portaluserslist");
        }
    });

    public saveUserCommand: ICommandAsync = new RelayCommandAsync(async () => {
        if (this.checkValidation()) {
            let _ = await this.apiClient.sendAsync(new CheckUserEndpoint(), this.model);
            if (this.apiClient.IsRequestSuccessful) {
                const checkUsersModel = this.apiClient.Response<PortalUsersModel>();
                if (checkUsersModel.hasFailedCoinsUserCheck) {
                    CoreStoreInstance.ShowInfoBar("This Email address does not match this Plot in Coins", "error");
                } else {
                    let _ = await this.apiClient.sendAsync(new SaveUserEndpoint(), this.model);
                    if (this.apiClient.IsRequestSuccessful) {
                        const portalUsersModel = this.apiClient.Response<PortalUsersModel>();
                        portalUsersModel.plotId = this.model.plotId;
                        portalUsersModel.userPlotId = this.model.userPlotId;
                        portalUsersModel.developmentId = this.model.developmentId;
                        let _ = await this.apiClient.sendAsync(new POSTUserPlotEndpoint(), portalUsersModel);
                        CoreStoreInstance.ShowInfoBar("User Saved Successfully", "success");
                        this.history.push("admin/dashboard/portaluserslist");
                    }
                }
            }
        }
    });

    public cancelSaveUserCommand: ICommand = new RelayCommand(async () => {
        this.history.push("admin/dashboard/portaluserslist");
    });

    public get(fieldName: any) {
        return this.getValue(fieldName);
    }

    public set(fieldName: any, value: string | number | boolean | Date) {
        this.setValue(fieldName, value);
    }

    public async loadUserAsync(userId: string): Promise<void> {
        await runInAction(async () => {
            this.userId = userId;
            const getUser = new GetUserByIdEndpoint(this, userId);
            await this.apiClient.sendAsync(getUser);
        });
        await runInAction(async () => {
            Logger.logDebug("loadUsersAsync: ", this.apiClient.Response<PortalUsersModel[]>());
            await this.apiClient.sendAsync(new GETPlotEndpoint(this.model.developmentId!, this));
        });
        await runInAction(async () => {
            this.updateField("plotId", this.model.plotId);
            this.firstName = this.model.firstName;
            this.lastName = this.model.lastName;
            this.editMail = this.model.emailAddress;
            this.newUser = false;
        });
    }

    public async resetFailedLoginAttempts(): Promise<void> {
        await this.apiClient.command.Post(
            AppUrls.Server.Admin.ResetFailedLoginAttemptsCount,
            {
                id: this.getValue("id"),
            },
            async (response) => {
                this.resetLoginAttemptsError = "";
            },
            () => {
                this.IsErrored = true;
                this.resetLoginAttemptsError = "Unknown Error resetting Failed Login Attempts Count";
            },
        );
    }
}

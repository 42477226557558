import { mergeStyleSets, Image, PrimaryButton } from "@fluentui/react";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

import { CompanyLogo } from "Assets/CompanyLogo";
import { AutoGrid, Box, Label, pxToRem, Show } from "../../Application";
import laptop from "Assets/laptop.jpg";
import clsx from "clsx";
import styled from "@emotion/styled";
import AnimateHeight from "react-animate-height";
import SignInView from "./SignIn/SignInView";
import RegisterView from "./SignUp/RegisterView";
import { AccountStore } from "../../Stores/Domain";
import { container } from "tsyringe";
import { GlobalHistory } from "../../index";
import { LoginHomeViewModel } from "./LoginHomeViewModel";
import { ForgotPasswordView } from "./SignIn/ForgotPassword/ForgotPasswordView";
import { NavLink, Route, Routes } from "react-router-dom";
import { LoginLogoSVG } from "../../Assets/SVGs/LoginLogoSVG";
import useMediaQuery from "Application/Hooks/useMediaQuery";

const LabelTitle = styled(Label)`
    font-size: 28px;
    font-weight: bold;
    margin-top: 15px;
    margin-bottom: 15px;
`;

export const styles = mergeStyleSets({
    root: {
        minHeight: "100%",
        width: "100%",
        background: "#fff",
    },
    content: {
        padding: pxToRem(20),
        margin: "auto",
    },
    leftContent: {
        margin: "auto",
        maxWidth: pxToRem(325),
    },
    maxHeight: {
        height: "100%",
        padding: "30px",
    },
    tab: {
        border: "1px solid #dcdcdc!important",
        height: "30px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textDecoration: "none",
    },
    selected: {
        backgroundColor: "white",
        color: "black",
    },
    notSelected: {
        backgroundColor: "#7F7F7F",
        color: "white",
    },
});

/**
 * Login page view.
 */

const LoginHomeView: React.FunctionComponent = observer(() => {
    // #region Code Behind
    const isMobile = useMediaQuery("(max-width: 767px)");
    const accountStore = container.resolve(AccountStore);
    const [viewModel] = useState(() => new LoginHomeViewModel());

    useEffect(() => {
        if (accountStore.IsLoggedIn) {
            GlobalHistory.push("/");
        }
    }, []);

    const doSubmit = (e: any) => {
        e.preventDefault();
    };

    // #endregion Code Behind

    const renderForm = () => (
        <form className={styles.root} onSubmit={doSubmit}>
            <AutoGrid className={styles.maxHeight} minHeight={"calc(100vh)"} columnGap={30} rowGap={20} mobileColumns={"1fr"} tabletColumns={"1fr"} desktopColumns={"1fr"}>
                <AutoGrid rowGap={isMobile ? "25px" : "50px"} dc={"1fr"} className={styles.leftContent} alignContent={"flex-start"}>
                    <Box center height={"154px"} width={"174px"} margin={"0 auto"}>
                        <LoginLogoSVG height={"154px"} width={"174px"} />
                    </Box>
                    {/* <LabelTitle>Customer Portal</LabelTitle> */}
                    {/* <Show if={viewModel.signUpButtonsVisible}>
                        <AutoGrid dc={"1fr 1fr"} columnGap={0}>
                            <NavLink to={"/account/login"} className={(navData) => clsx(styles.tab, navData.isActive ? styles.selected : styles.notSelected)}>
                                Sign In
                            </NavLink>
                            <NavLink to={"/account/register"} className={(navData) => clsx(styles.tab, navData.isActive ? styles.selected : styles.notSelected)}>
                                Sign Up
                            </NavLink>
                        </AutoGrid>
                    </Show> */}
                    <AnimateHeight id="login-panel" duration={500} height={"auto"}>
                        <Routes>
                            <Route path="login" element={<SignInView loginHomeViewModel={viewModel} />} />
                            <Route path="register/*" element={<RegisterView loginHomeViewModel={viewModel} />} />
                            <Route path="confirmemail/:token/*" element={<ForgotPasswordView confirmEmail={true} loginHomeViewModel={viewModel} />} />
                            <Route path="forgotpassword/*" element={<ForgotPasswordView loginHomeViewModel={viewModel} />} />
                            <Route path="resetpassword/:token" element={<ForgotPasswordView loginHomeViewModel={viewModel} />} />
                        </Routes>
                    </AnimateHeight>
                </AutoGrid>
            </AutoGrid>
        </form>
    );

    return <React.Fragment>{renderForm()}</React.Fragment>;
});
export default LoginHomeView;

import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Box, setPageTitle, Label, formatDate } from "Application";
import { StagesViewModel } from "./StagesViewModel";
import styled from "@emotion/styled";
import BuildComplete from "Assets/SVGs/BuildComplete";
import WriteIcon from "Assets/SVGs/WriteIcon";

const Container = styled(Box)`
    background-color: transparent;
    border-radius: 10px;
    min-height: 100px;
    width: 100%;
`;

const ImgCard = styled(Box)`
    overflow: hidden;
    border-radius: 50%;
    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
`;

interface Props {
    viewModel: StagesViewModel;
    index: number;
    total: number;
}
export const StagesView: React.FC<Props> = observer((props: Props) => {
    const { viewModel } = props;
    const divRef = React.useRef<any>(null);

    useEffect(() => {
        setPageTitle("Stages");
    }, []);
    useEffect(() => {
        if (props.index === props.total) {
            //divRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
        }
    });

    const description = props.viewModel.model.description;

    return (
        <div ref={divRef} id={props.index == props.total ? "finalstage" : "stage" + props.index}>
            <Box mt={0} position="relative">
                <Box grid dc={"145px 1fr"} justifyContent={"center"} alignItems={"center"} style={{ gap: 0 }}>
                    {/* <BuildProgressSVG style={{ transform: "scale(1.5)" }} showblankcircle={true} /> */}
                    <Box height={"96px"} width={"96px"} background={"#fff"} borderRadius={"100%"} position={"relative"}>
                        {/* {props.viewModel.model.imageUrl ? <img src={props.viewModel.model.imageUrl} width="96" height="96" /> : <BuildComplete />} */}
                        {props.viewModel.model.imageUrl ? (
                            <ImgCard height={"96px"} width={"96px"} border={"5px solid rgb(184, 142, 0)"}>
                                <img src={props.viewModel.model.imageUrl} alt={props.viewModel.model.imageUrl} />
                            </ImgCard>
                        ) : (
                            <BuildComplete />
                        )}
                        {props.viewModel.model.isVisible && (
                            <Box position={"absolute"} bottom={"0"} right={"0"}>
                                <WriteIcon />
                            </Box>
                        )}
                    </Box>
                    <Box
                        flexBox
                        background={props.viewModel.model.isVisible ? "#F0F9EF" : "#fff"}
                        borderRadius={"10px"}
                        border={"2px solid transparent"}
                        borderColor={props.viewModel.model.isVisible ? "#D8F1D6" : "#e7e7e7"}
                        style={{ gap: 0 }}
                        position={"relative"}
                        alignItems="center"
                    >
                        <Box
                            height={"40px"}
                            width={"40px"}
                            border={"2px solid transparent"}
                            borderLeftColor={props.viewModel.model.isVisible ? "#D8F1D6" : "#e7e7e7"}
                            borderBottomColor={props.viewModel.model.isVisible ? "#D8F1D6" : "#e7e7e7"}
                            borderRadius={"0 0 0 5px"}
                            background={props.viewModel.model.isVisible ? "#F0F9EF" : "#fff"}
                            left={"-20px"}
                            position={"absolute"}
                            style={{ transform: "rotate(45deg)" }}
                        ></Box>
                        <Container p={"15px 30px"}>
                            <Label
                                fontStyle={"h4"}
                                color={"black"}
                                style={{
                                    gap: "8px",
                                    display: "flex",
                                    font: "normal normal bold 14px/22px Lato",
                                    letterSpacing: "-0.24px",
                                    color: "#333333",
                                    borderBottom: "1px dashed #BEBEBE",
                                    margin: "0 0 15px",
                                    paddingBottom: "15px",
                                }}
                            >
                                Stage {props.index} of {props.total}
                                <span
                                    style={{
                                        font: "normal normal normal 12px/22px Lato",
                                        letterSpacing: "-0.2px",
                                        color: props.viewModel.model.isVisible ? "#1DC10F" : "#00B9EE",
                                    }}
                                >
                                    {props.viewModel.model.isVisible ? "(complete)" : "(current)"}
                                </span>
                            </Label>
                            <Label
                                fontStyle={"h3"}
                                color={"black"}
                                style={{ font: "normal normal bold 18px/25px Lato", letterSpacing: "-0.31px", color: "#4A4A4A", margin: "0 0 5px" }}
                            >
                                {viewModel.getValue("title")}
                            </Label>
                            <Label
                                fontStyle={"h5"}
                                color={"black"}
                                style={{ font: "normal normal normal 12px/15px Lato", letterSpacing: "0px", color: "#333333", margin: "0 0 15px" }}
                            >
                                {viewModel.getValue("stageDate") ? formatDate(viewModel.getValue("stageDate")) : "-"}
                            </Label>
                            <Label
                                mt={3}
                                fontStyle={"h4"}
                                color={"black"}
                                style={{ fontWeight: 400, font: "normal normal normal 14px/15px Lato", letterSpacing: "0px", color: "#333333" }}
                            >
                                {description.replace(/(<([^>]+)>)/gi, " ")}
                            </Label>
                        </Container>
                    </Box>
                </Box>
            </Box>
        </div>
    );
});

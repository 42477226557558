import { Link } from "@fluentui/react";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

import { LoginViewModel } from "./LoginViewModel";
import { Box, setPageTitle, Button, Label, CheckBox, Input } from "../../../Application";

import { ThemedLoader } from "../../../Styles/ThemedPrimitives/Style1/ThemedLoader";
import styled from "@emotion/styled";
import { LoginHomeViewModel } from "../LoginHomeViewModel";
import { PasswordSVG } from "../../../Assets/Icons/PasswordSVG";
import { PasswordSVGVisible } from "../../../Assets/Icons/PasswordSVGVisible";

const LabelSmall = styled(Label)`
    font-size: 12px;
    font-weight: 400;
`;

const LoginForm = styled(Box)`
    font-size: 12px;
    font-weight: 400;
    .formField {
        margin-bottom: 26px;
        @media (max-width: 767px) {
            margin-bottom: 18px;
        }
        & > div > div:first-child {
        }
    }
    label.placeholder,
    label.select-label {
        font: normal normal 300 14px/17px Lato;
        letter-spacing: 0.7px;
        color: #4a4a4a;
        text-transform: uppercase;
        margin: 0 0 5px;
    }
    input {
        height: 32px;
        font: normal normal normal 12px/15px Lato;
        letter-spacing: 0.36px;
        color: #707070 !important;
        width: 100% !important;
    }
    input: -internal-autofill-selected {
        background-color: #fff !important;
    }
    .inputbox:focus,
    .inputbox:focus-within {
        border: 1px solid #b88e00 !important;
    }
    input[type="checkbox"] {
        width: 30px !important;
        border-radius: 0 !important;
        height: 30px !important;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #cccbcb !important;
        transform: inherit !important;
        cursor: pointer;
        & + div label {
            font: normal normal normal 14px/17px Lato;
            letter-spacing: 0.7px;
            color: #4a4a4a;
            margin: 0;
        }
    }
    button label {
        margin: 0;
        text-decoration: underline;
        font: normal normal normal 9px/10px Lato;
        letter-spacing: 0.24px;
        color: #b88e00;
    }
`;

/**
 * Login page view.
 */
const LoginView = observer(({ loginHomeViewModel }: { loginHomeViewModel: LoginHomeViewModel }) => {
    // #region Code Behind
    const [viewModel] = useState(() => new LoginViewModel());
    const [visible, setVisible] = useState(false);
    useEffect(() => {
        setPageTitle("Sign In");
    }, []);
    // #endregion Code Behind

    const handleEyeClick = () => {
        setVisible(!visible);
    };

    const getEyeIcon = () => {
        if (!visible) {
            return <PasswordSVG onClick={handleEyeClick} style={{ transform: "scale(1)", transformOrigin: "26px 6px" }} />;
        }
        return <PasswordSVGVisible onClick={handleEyeClick} style={{ transform: "scale(1)", transformOrigin: "26px 6px" }} />;
    };

    const renderForm = () => (
        <>
            <LoginForm alignContent={"center"} dc="1fr" rowGap={"30px"} minWidth={"262px"}>
                <Label mb={"10px"} style={{ color: "red" }}>
                    {/* {viewModel.setLoginValidationMessage} */}
                    {viewModel.apiClient.ValidationMessage}
                </Label>
                <Box className="formField">
                    <Input
                        labelColor={"black"}
                        command={viewModel.updateEmailCommand}
                        displayName="Login"
                        canClearText={true}
                        value={() => viewModel.getValue("emailAddress")}
                        validationMessage={() => viewModel.getError("emailAddress")}
                        placeholder="Username or email address"
                    />
                </Box>
                <Box className="formField">
                    <Input
                        labelColor={"black"}
                        command={viewModel.updatePasswordCommand}
                        suffix={getEyeIcon()}
                        placeholder="Password"
                        displayName="Password"
                        type={!visible ? "password" : "text"}
                        canClearText={true}
                        value={() => viewModel.getValue("password")}
                        validationMessage={() => viewModel.getError("password")}
                    />
                </Box>
                <Box display={"flex"} justifyContent={"space-between"} my={"30px"} style={{ float: "right", marginTop: 0 }}>
                    {/* <CheckBox
                        className="checkBoxModule"
                        command={viewModel.rememberMeCommand}
                        value={() => viewModel.getValue("rememberMe")}
                        displayName={"Remember me"}
                        labelColor={"black"}
                    /> */}
                    <Link onClick={() => loginHomeViewModel.navigateToForgotPasswordCommand.execute()}>
                        <Label color={"blue"} fontStyle={"h7"} style={{ cursor: "pointer" }}>
                            Forgot Password?
                        </Label>
                    </Link>
                </Box>
                <Button
                    displayName="Continue"
                    command={viewModel.loginCommand}
                    paletteColor="secondary"
                    type="submit"
                    style={{ width: "100%", borderRadius: 0, font: "normal normal 300 12px/16px Lato", letterSpacing: "0.36px", color: "#FFFFFF", textTransform: "uppercase" }}
                />
                <Box className="formField" style={{ marginTop: "30px" }}>
                    <p style={{ font: "normal normal normal 12px/14px Lato", letterSpacing: "0px", color: "#4A4A4A", marginBottom: "15px" }}>
                        Welcome to your online new home portal. Here you can track the build progress of your new home, access helpful guides, view important documentation and much
                        more - all in one easy to use space.
                    </p>
                    {/* <p style={{ font: "normal normal normal 12px/14px Lato", letterSpacing: "0px", color: "#4A4A4A" }}>
                        You can check your progress, ask questions, view photos and download your documentation all from the comfort of your home.
                    </p> */}
                </Box>
            </LoginForm>
        </>
    );

    const renderBusy = () => <ThemedLoader isOpen={viewModel.apiClient.IsBusy} />;

    return (
        <React.Fragment>
            {renderForm()}
            {renderBusy()}
        </React.Fragment>
    );
});
export default LoginView;

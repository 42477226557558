import * as React from "react";
import { SVGProps } from "react";
import { theme } from "../../Styles";
import { Box } from "../../Components";
interface Props extends SVGProps<SVGSVGElement> {
    showblankcircle: boolean;
}
export const YourHomeSVG: React.FC<Props> = (props: Props) => {
    const { showblankcircle, ...rest } = props;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={68} height={68} {...rest}>
            <defs>
                <filter id="a" x={0} y={0} width={68} height={68} filterUnits="userSpaceOnUse">
                    <feOffset dy={3} />
                    <feGaussianBlur stdDeviation={3} result="blur" />
                    <feFlood floodOpacity={0.251} />
                    <feComposite operator="in" in2="blur" />
                    <feComposite in="SourceGraphic" />
                </filter>
            </defs>
            <g data-name="Group 8266">
                {showblankcircle && (
                    <g data-name="Ellipse 209" transform="translate(9 6)" fill={theme.palette.primary.main} stroke={theme.palette.primary.main} strokeWidth={2.5}>
                        <circle cx={25} cy={25} r={30} stroke="none" />
                        <circle cx={25} cy={25} r={27.75} fill="none" />
                    </g>
                )}
                <g filter="url(#a)" data-name="Group 8264">
                    <g data-name="Ellipse 209" transform="translate(9 6)" fill="#806302" stroke="#b88e00" strokeWidth={2.5}>
                        <circle cx={25} cy={25} r={25} stroke="none" />
                        <circle cx={25} cy={25} r={23.75} fill="none" />
                    </g>
                </g>
                <g data-name="Group 8265" fill="#FFF">
                    <path
                        data-name="Path 3847"
                        d="M34.811 21.799a.47.47 0 0 0-.623 0l-9.277 8.19a.47.47 0 0 0-.159.353v11.422a.47.47 0 0 0 .47.47h3.082a.47.47 0 0 0 .47-.47v-7.7h3.921v7.7a.47.47 0 0 0 .47.47h10.61a.47.47 0 0 0 .47-.47V30.342a.472.472 0 0 0-.159-.353Zm1.626 15.1v-2.835h3.921v2.835Z"
                    />
                    <path
                        data-name="Path 3848"
                        d="m46.841 29.735-2.593-2.289v-4.5a.47.47 0 0 0-.47-.47H41.87a.47.47 0 0 0-.47.47v1.989l-6.588-5.816-.014-.011a.55.55 0 0 0-.078-.052l-.044-.021a.546.546 0 0 0-.043-.016.443.443 0 0 0-.044-.01.431.431 0 0 0-.044-.007h-.091l-.047.008-.041.01a.615.615 0 0 0-.085.035l-.039.024c-.013.009-.027.018-.04.029l-.014.011-12.034 10.616a.47.47 0 0 0 .623.705L34.5 20.096l11.718 10.345a.47.47 0 1 0 .623-.705Z"
                    />
                </g>
            </g>
        </svg>
    );
};

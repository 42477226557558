import * as React from "react";
import { SVGProps } from "react";

export const DashboardSVG = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={67.93} height={67.93} {...props}>
        <defs>
            <filter id="a" x={0} y={0} width={67.93} height={67.93} filterUnits="userSpaceOnUse">
                <feOffset dy={3} />
                <feGaussianBlur stdDeviation={3} result="blur" />
                <feFlood floodOpacity={0.251} />
                <feComposite operator="in" in2="blur" />
                <feComposite in="SourceGraphic" />
            </filter>
        </defs>
        <g data-name="Group 5654">
            <g filter="url(#a)" data-name="Group 5613">
                <g data-name="Ellipse 209" transform="translate(9 6)" fill="#806302" stroke="#b88e00" strokeWidth={2.5}>
                    <circle cx={24.965} cy={24.965} r={24.965} stroke="none" />
                    <circle cx={24.965} cy={24.965} r={23.715} fill="none" />
                </g>
            </g>
            <g data-name="Group 5836">
                <path
                    data-name="Path 3844"
                    d="M31.682 23.424v7.235a1.75 1.75 0 0 1-1.75 1.75h-3.676a1.75 1.75 0 0 1-1.75-1.75v-7.235a1.75 1.75 0 0 1 1.75-1.75h3.676a1.75 1.75 0 0 1 1.75 1.75Zm-1.75 10.316h-3.676a1.75 1.75 0 0 0-1.75 1.754v3.851a1.75 1.75 0 0 0 1.75 1.749h3.676a1.75 1.75 0 0 0 1.75-1.75v-3.85a1.75 1.75 0 0 0-1.75-1.754Zm12.241-4.306h-7.41a1.75 1.75 0 0 0-1.75 1.75v8.157a1.75 1.75 0 0 0 1.75 1.75h7.41a1.75 1.75 0 0 0 1.75-1.75v-8.157a1.75 1.75 0 0 0-1.75-1.75Zm0-7.76h-7.41a1.75 1.75 0 0 0-1.75 1.75v2.929a1.75 1.75 0 0 0 1.75 1.75h7.41a1.75 1.75 0 0 0 1.75-1.75v-2.929a1.75 1.75 0 0 0-1.75-1.75Z"
                    fill="#FFF"
                />
            </g>
        </g>
    </svg>
);

import * as React from "react";
import { SVGProps } from "react";

export const LogoutSVG = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={68.018} height={68.018} {...props}>
        <defs>
            <clipPath id="b">
                <path data-name="Rectangle 1540" fill="#ee732c" d="M0 0h20.841v20.843H0z" />
            </clipPath>
            <filter id="a" x={0} y={0} width={68.018} height={68.018} filterUnits="userSpaceOnUse">
                <feOffset dy={3} />
                <feGaussianBlur stdDeviation={3} result="blur" />
                <feFlood floodOpacity={0.251} />
                <feComposite operator="in" in2="blur" />
                <feComposite in="SourceGraphic" />
            </filter>
        </defs>
        <g data-name="Group 5654">
            <g filter="url(#a)" data-name="Group 5613">
                <g data-name="Ellipse 209" transform="translate(9 6)" fill="#806302" stroke="#b88e00" strokeWidth={2.5}>
                    <circle cx={25.009} cy={25.009} r={25.009} stroke="none" />
                    <circle cx={25.009} cy={25.009} r={23.759} fill="none" />
                </g>
            </g>
            <g data-name="Group 5664">
                <g data-name="Group 5516" transform="translate(24.283 21.283)" clipPath="url(#b)">
                    <path
                        data-name="Path 3677"
                        d="M.581 0A.651.651 0 0 0 0 .65v19.54a.651.651 0 0 0 .647.655h11.4a.651.651 0 0 0 .647-.655v-4.884a.651.651 0 1 0-1.3 0v4.237H1.3V1.3h10.1v4.236a.651.651 0 1 0 1.3 0V.65a.648.648 0 0 0-.65-.65H.647a.3.3 0 0 0-.066 0m14.713 5.93a.654.654 0 0 0-.63.65v1.888H5.076a.651.651 0 0 0-.652.65v2.6a.65.65 0 0 0 .652.65h9.588v1.891a.652.652 0 0 0 1.054.51l4.874-3.838a.653.653 0 0 0 .111-.916.66.66 0 0 0-.111-.108L15.718 6.07a.646.646 0 0 0-.423-.14"
                        fill="#fff"
                    />
                </g>
            </g>
        </g>
    </svg>
);

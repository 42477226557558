import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { LookupStore } from "Stores/Domain/LookupStore";
import { container } from "tsyringe";
import styled from "@emotion/styled";
import {
    AutoGrid,
    Box,
    ThemedCheckBox,
    ThemedComboBox,
    ThemedButton,
    ThemedEditText,
    ThemedText,
    ThemedEditDate,
    ThemedRadioButtons,
    setPageTitle,
    DashedLine,
    Label,
} from "Application";
import { DocumentListViewModel } from "./DocumentListViewModel";
import { CoinsPageContainer } from "../../Styles/BaseStyles";
import { StagesView } from "../Salesprogress/Stages/StagesView";
import { DocumentView } from "./DocumentView";
import useMediaQuery from "Application/Hooks/useMediaQuery";

const FieldLabel = styled(Label)`
    font-size: 14px;
    font-weight: 400;
`;

const Container = styled(Box)`
    /* overflow-y: auto; */
    max-width: 1200px;
    margin: 0 auto;
    margin: 0 auto;
    & > div:last-child {
        border-bottom: none;
    }
`;

export const DocumentListView: React.FC = observer(() => {
    const isMobile = useMediaQuery("(max-width: 767px)");
    const [viewModel] = useState(() => new DocumentListViewModel());
    const lookupStore = container.resolve(LookupStore);

    useEffect(() => {
        setPageTitle("Documents");
    }, []);
    return (
        <>
            {/* <Box background={"#325083"} padding={isMobile ? "10px 15px 45px" : "25px 15px"}>
                <Container id={"rootcontainer"}>
                    <Label
                        fontStyle={"h1"}
                        mt={"0"}
                        mb={"6px"}
                        style={{ letterSpacing: "-0.24px", color: "#FFFFFF", textTransform: "uppercase", opacity: 1, fontSize: "24px", lineHeight: "30px", fontWeight: "normal" }}
                    >
                        Documents
                    </Label>
                    <Label
                        fontStyle={"h3"}
                        style={{ letterSpacing: "-0.24px", fontSize: "16px", lineHeight: "21px", color: "#FFFFFF", textTransform: "uppercase", fontWeight: 300 }}
                    >
                        Plot {viewModel.getValue("plotNumber")} {viewModel.getValue("developmentName")}
                    </Label>
                    <FieldLabel fontStyle={"h4"} style={{ letterSpacing: "-0.15px", color: "#DCB224", textTransform: "uppercase", fontSize: "10px", lineHeight: "21px" }}>
                        {`${viewModel.getValue("address1") !== null ? viewModel.getValue("address1") : ""} ${
                            viewModel.getValue("address2") !== null ? viewModel.getValue("address2") : ""
                        } ${viewModel.getValue("address3") !== null ? viewModel.getValue("address3") : ""} ${
                            viewModel.getValue("address4") !== null ? viewModel.getValue("address4") : ""
                        } ${viewModel.getValue("postcode") !== null ? viewModel.getValue("postcode") : ""}`}
                    </FieldLabel>
                </Container>
            </Box> */}
            <Box px={"15px"}>
                <Container id={"rootcontainer"}>
                    {/* <Box py={"28px"} grid dc={isMobile ? "100%" : "25% 25% 25% 25%"} rowGap={"25px"} borderBottom={"1px dashed #BEBEBE"}>
                        <DocumentView title={"Signed Reservation"} fileName={"DEMO Res Form Template.pdf"} />
                        <DocumentView title={"Handover Certificate"} fileName={"Demo Handover Certifcate.pdf"} />
                    </Box>
                    <Box py={"28px"} grid dc={isMobile ? "100%" : "25% 25% 25% 25%"} rowGap={"0"} borderBottom={"1px dashed #BEBEBE"}>
                        <DocumentView title={"Pre-Handover Documents"} fileName={"Demo  Handover Guarantee Document.pdf"} />
                        <DocumentView title={""} fileName={"Demo Redbourne SAP Test Results.pdf"} />
                        <DocumentView title={""} fileName={"Demo Harriers Rest Brochure.pdf"} />
                    </Box>
                    <Box py={"28px"} grid dc={isMobile ? "100%" : "25% 25% 25% 25%"} rowGap={"0"} borderBottom={"1px dashed #BEBEBE"}>
                        <DocumentView title={"Handover Documents"} fileName={"Demo  Handover Guarantee Document.pdf"} />
                    </Box>
                    <Box py={"28px"} grid dc={isMobile ? "100%" : "25% 25% 25% 25%"} rowGap={"0"} borderBottom={"1px dashed #BEBEBE"}>
                        <DocumentView title={"Aftercare Documents"} fileName={"Demo Aftercare Guarantee Document.pdf"} />
                    </Box> */}
                    {viewModel.model.documentModels.map((documentListModel, index) => {
                        return (
                            <React.Fragment key={documentListModel.KEY}>
                                <Box py={"28px"} grid dc={isMobile ? "100%" : "25% 25% 25% 25%"} rowGap={"0"} borderBottom={"1px dashed #BEBEBE"}>
                                    <DocumentView
                                        viewModel={viewModel}
                                        id={documentListModel.documentId}
                                        title={documentListModel.documentTypeLongDescription}
                                        fileName={documentListModel.fileName}
                                    />
                                </Box>
                            </React.Fragment>
                        );
                    })}
                    {/*<ThemedButton mt={3} displayName={"Add Stages"} command={viewModel.addStagesCommand} />*/}
                </Container>
            </Box>
            {/* <div style={{ marginTop: viewModel.isMobile ? "20px" : "30px" }}>
                <CoinsPageContainer title={"Documents"} ml={viewModel.isMobile ? "10px" : "30px"} mr={viewModel.isMobile ? "10px" : "20px"}></CoinsPageContainer>
            </div> */}
        </>
    );
});

import React, { PropsWithChildren, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { autorun } from "mobx";
import { CoreStoreInstance } from "@shoothill/core/dist/Stores/CoreStore";
import { Box, BoxProps } from "../StyleFrameworks";
import { gridColumnGap } from "styled-system";
import { MotionProps } from "framer-motion/";

export type AutoGridProps = {
    mobileColumns?: string;
    mc?: string;
    tabletColumns?: string;
    tc?: string;
    desktopColumns?: string;
    dc?: string;
    mobileRows?: string;
    mr?: string;
    tabletRows?: string;
    tr?: string;
    desktopRows?: string;
    dr?: string;
    gridAutoColumns?: string;
    mobileArea?: string[];
    ma?: string[];
    tabletArea?: string[];
    ta?: string[];
    desktopArea?: string[];
    da?: string[];
    areaName?: string;
    columnGap?: string | number;
    rowGap?: string | number;
    //gap?: string | number;
} & PropsWithChildren<BoxProps> &
    MotionProps;

const formatAreas = (areas: string[]) => {
    if (areas && areas.length > 0) {
        return areas.map((area) => `"${area}"`).join(" ");
    }
    return "";
};

export const AutoGrid: React.FC<AutoGridProps> = observer((props: AutoGridProps) => {
    const [columns, setColumns] = React.useState("");
    const [renderComplete, setRenderComplete] = React.useState(false);
    const [rows, setRows] = React.useState(props.desktopRows);
    const [area, setArea] = React.useState<string[]>(props.desktopArea!);
    const [view, setView] = React.useState("desktop");

    let { desktopColumns, tabletColumns, mobileColumns, desktopRows, tabletRows, mobileRows, desktopArea, tabletArea, mobileArea, columnGap, rowGap, ...boxProps } = props;

    useEffect(() => {
        //By default make tablet the same as desktop
        tabletColumns = tabletColumns === undefined ? desktopColumns : tabletColumns;
        tabletArea = tabletArea === undefined ? desktopArea : tabletArea;
        tabletRows = tabletRows === undefined ? desktopRows : tabletRows;

        desktopColumns = desktopColumns ?? props.dc;
        tabletColumns = tabletColumns ?? props.tc;
        mobileColumns = mobileColumns ?? props.mc;

        desktopRows = desktopRows ?? props.dr;
        tabletRows = tabletRows ?? props.tr;
        mobileRows = mobileRows ?? props.mr;

        desktopArea = desktopArea ?? props.da;
        tabletArea = tabletArea ?? props.ta;
        mobileArea = mobileArea ?? props.ma;

        desktopColumns = desktopColumns || "1fr 1fr";
        tabletColumns = tabletColumns || desktopColumns;
        autorun(() => {
            //console.log("autorun of grid");
            if (CoreStoreInstance.isMobile) {
                setColumns(mobileColumns!);
                setRows(mobileRows!);
                setArea(mobileArea!);
                setView("mobile");
            } else if (CoreStoreInstance.isTablet) {
                setColumns(tabletColumns!);
                setRows(tabletRows!);
                setArea(tabletArea!);
                setView("tablet");
            } else if (CoreStoreInstance.isDesktop) {
                setColumns(desktopColumns!);
                setRows(desktopRows!);
                setArea(desktopArea!);
                setView("desktop");
            } else {
                setColumns(desktopColumns!);
                setRows(desktopRows!);
                setArea(desktopArea!);
                setView("desktop");
            }
            setRenderComplete(true);
        });
    }, []);

    {
        /*{...boxProps}*/
    }
    return (
        <Box
            {...boxProps}
            style={{
                display: renderComplete ? "grid" : "none",
                gridTemplateColumns: columns ?? "",
                gridTemplateRows: rows ?? "",
                gridRowGap: rowGap ?? "",
                gridColumnGap: columnGap ?? "",
                //gridGap: getGap(),
                gridTemplateAreas: formatAreas(area),
            }}
        >
            {props.children}
        </Box>
    );
});

AutoGrid.defaultProps = {
    columnGap: "1em",
    rowGap: "0.5em",
    //paddingTop: "0",
    //mobileColumns: "1fr",
    //tabletColumns: "1fr 1fr",
    //desktopColumns: "1fr 1fr",
    justifyContent: "normal",
    alignContent: "normal",
};

import { Logger } from "index";
import { Endpoint, EndpointWithoutRequest, Http } from "Application/Helpers/BaseEndpoint";
import { runInAction } from "mobx";
import { DocumentListModel } from "../DocumentListModel";
import { DocumentListViewModel } from "../DocumentListViewModel";

class Response {
    public documents: DocumentResponse[] = [];
}
class DocumentResponse {
    public documentId: string | null = "";
    public documentTypeLongDescription: string | null = "";
    public fileName: string | null = "";
    public documentUrl: string | null = "";
}

export class GETDocumentsEndpoint extends EndpointWithoutRequest<Response> {
    private viewModel: DocumentListViewModel;
    constructor(viewModel: DocumentListViewModel, coinsPlotId: string) {
        super();
        this.verb(Http.Get);
        this.path("/api/getdocuments/" + coinsPlotId);
        this.viewModel = viewModel;
    }

    public async HandleResponseAsync(response: Response): Promise<any> {
        Logger.logInformation("Response", response);

        runInAction(() => {
            for (const item of response.documents) {
                let m = new DocumentListModel();
                m.documentId = item.documentId!;
                m.documentTypeLongDescription = item.documentTypeLongDescription!;
                m.fileName = item.fileName!;
                m.documentUrl = item.documentUrl!;
                this.viewModel.model.documentModels.push(m);
            }
        });
        //Return your model
        return {};
    }
}

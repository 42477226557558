import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { Box } from "Application";
import styled from "@emotion/styled";
import { motion } from "framer-motion/";

const Circle = styled(Box)`
    background-color: white;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    border: 3px solid #b88e00;
`;
const Container = styled(motion.div)`
    position: absolute;
    border-radius: 32px;
    width: 48px;
    height: 48px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    top: 0;
    right: 0;
    //margin: 24px;
    cursor: pointer;
    z-index: 3;
    pointer-events: all;
    /* -webkit-tap-highlight-color: transparent; */
`;
const Line = styled(motion.div)`
    background-color: #b88e00;
    width: ${({ width }: { width: any }) => `${width}px`};
    height: 4px;
    position: absolute;
    margin: 2px 6px;
    border-radius: 8px;
`;

interface Props {
    isExpanded: boolean;
}
export const LargePlus: React.FC<Props> = observer((props) => {
    return (
        <Circle>
            <Container>
                <Line width={32} animate={{ rotate: props.isExpanded ? "-45deg" : "90deg" }} transition={{ duration: 0.5 }} />
                <Line width={32} animate={{ rotate: props.isExpanded ? "-135deg" : "0deg" }} transition={{ duration: 0.5 }} />
            </Container>
        </Circle>
    );
});

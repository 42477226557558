import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { AutoGrid, Box, setPageTitle, theme } from "Application";
import { PanelViewModel } from "./PanelViewModel";
import styled from "@emotion/styled";
import { Label } from "../../../Components/Primitives/Labels";
import { RightArrowSVG } from "../../../Assets/SVGs/RightArrowSVG";
import { HorizontalBlock } from "../../../Components/HorizontalBlock";
import { isNullOrEmpty } from "@shoothill/core";
import moment from "moment";

interface PanelProps {
    image: string;
}
const Panel = styled(Box)<PanelProps>`
    /* width: 174px; */
    /* height: 665px; */
    background-color: #325083;
    /* background-image: ${(p) => `url(${p.image})`}; */
    background-repeat: no-repeat;
    border-radius: 2px;
    /* box-shadow: 0 3px 6px #00000040; */
    cursor: pointer;
`;

interface Props {
    image: string;
    icon: JSX.Element;
    viewModel: PanelViewModel;
    delay: number;
    path: string;
    description: string;
    title: any;
    subTitle?: string;
    lastUpdatedDate: string;
    view?: "homeView" | "panelView" | "snagView";
    style?: any;
}
export const PanelView: React.FC<Props> = observer((props: Props) => {
    const { viewModel } = props;
    const [isHover, setIsHover] = useState(false);
    useEffect(() => {
        setPageTitle("Panel");
    }, []);

    const PanelForDesktop = () => {
        return (
            <Panel
                style={props.style}
                onClick={() => viewModel.navigateCommand.execute(props.path)}
                image={props.image}
                /* initial={{
                y: "-50px",
            }}
            animate={{ y: 0, x: 0 }}*/
                transition={{ type: "tween", duration: 0.1, delay: props.delay }}
                // whileHover={{ scale: 1.05 }}
                onHoverStart={() => setIsHover(true)}
                onHoverEnd={() => setIsHover(false)}
            >
                <img src={props.image} className="img-fluid" alt="image" style={{ width: "100%", height: "500px", objectFit: "cover" }} />
                <Box flexBox justifyContent={"center"} mt={"-38px"}>
                    {props.icon}
                </Box>
                <Box padding={"0px 25px 25px"} grid dc="1fr" rowGap={"5px"}>
                    <Box textAlign={"center"}>
                        <Label
                            style={{ fontWeight: "normal", fontSize: "24px", lineHeight: "24px", letterSpacing: "0px", color: "#FFFFFF", textTransform: "uppercase" }}
                            fontStyle={"h4"}
                        >
                            {props.title}
                        </Label>
                        {/* <Box
                            mr={"15px"}
                            mt={"5px"}
                            animate={{ x: isHover ? ["0px", "-5px", "0px"] : ["0px", "0px"] }}
                            transition={{ type: "tween", duration: 1, repeat: Infinity, delay: props.delay }}
                        >
                            <RightArrowSVG />
                        </Box> */}
                        <Label
                            fontStyle={"h5"}
                            style={{ letterSpacing: "0px", marginTop: "1px", color: "#FFFFFF", textTransform: "uppercase", fontSize: "12px", lineHeight: "18px", fontWeight: 300 }}
                        >
                            {props.subTitle}
                        </Label>
                        <Label
                            fontStyle={"h5"}
                            style={{ letterSpacing: "0px", fontSize: "12px", lineHeight: "14px", color: "#FFFFFF", textTransform: "uppercase", margin: "20px 0 0" }}
                        >
                            {props.description}
                        </Label>
                        {/* <Label
                            fontStyle={"h6"}
                            mt={"1"}
                            style={{
                                textTransform: "none",
                                letterSpacing: "0px",
                                fontSize: "10px",
                                lineHeight: "14px",
                                color: "#DCB224",
                                margin: "20px 0 0",
                            }}
                            color={"blue"}
                        >
                            {!isNullOrEmpty(props.lastUpdatedDate) && <p>Last updated {props.lastUpdatedDate}</p>}
                        </Label> */}
                    </Box>
                </Box>
            </Panel>
        );
    };

    const PanelForMobile = () => {
        return (
            <Box grid dc={"1fr"} style={{ width: "100%" }}>
                <HorizontalBlock
                    title={props.title}
                    subTitle={props.subTitle}
                    description={props.description}
                    lastUpdated={props.lastUpdatedDate}
                    image={props.image}
                    icon={props.icon}
                    path={props.path}
                    view={props.view}
                />
            </Box>
        );
    };

    return <>{viewModel.isMobile ? PanelForMobile() : PanelForDesktop()}</>;
});

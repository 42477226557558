import { makeObservable, observable, observe, runInAction } from "mobx";
import { ViewModelBase, isNullOrUndefined } from "@shoothill/core";
import { APIClient, IKeyState, RelayCommand } from "Application";
import { DocumentListModel, DocumentsModelValidator } from "./DocumentListModel";
import { Logger } from "index";
import { GETDocumentsEndpoint } from "./Endpoints/GETDocumentsEndpoint";
import { GETDocumentByIdEndpoint } from "./Endpoints/GETDocumentByIdEndpoint";
import { container } from "tsyringe";
import { AccountStore } from "../../Stores/Domain";

export class DocumentListViewModel extends ViewModelBase<DocumentListModel> {
    private accountStore = container.resolve(AccountStore);
    public apiClient = new APIClient();

    constructor(model = new DocumentListModel()) {
        super(model);
        this.setValidator(new DocumentsModelValidator());
        makeObservable(this, {});
        this.apiClient.sendAsync(new GETDocumentsEndpoint(this, this.accountStore.CoinsPlotId!), this.model);
    }

    public getDocumentCommand = new RelayCommand(async (documentId: string) => {
        return await this.apiClient.sendAsync(new GETDocumentByIdEndpoint(documentId));
    });

    //#region Update Commands

    //#endregion Commands
}

import React, { useEffect, useState } from "react";
import { AutoGrid } from "Components/Grid";
import styled from "@emotion/styled";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router";
import { Box, ThemedEditText, ThemedComboBox, Button, Label } from "../../../../../Application";
import { BackPageSVG } from "Assets/SVGs/BackPage";
import PortalUserDetailsViewModel from "./PortalUserDetailsViewModel";
import arrow from "Assets/lockLeftArrow.png";
import useMediaQuery from "Application/Hooks/useMediaQuery";
import { container } from "tsyringe";
import { AccountStore } from "../../../../../Stores/Domain";

export const ContainerBox = styled(Box)``;
export const DetailContainer = styled(Box)`
    position: relative;
`;

const Container = styled(Box)`
    max-width: 1200px;
    margin: 0 auto;
    margin: 0 auto;
`;

const UserForm = styled(Box)`
    span,
    .ms-Label {
        font: normal normal 300 14px/17px Lato;
        letter-spacing: 0.7px;
        color: #191919;
        text-transform: uppercase;
        margin: 0 0 6px;
        padding: 0;
    }
    .ms-TextField-wrapper {
        & > div:first-child {
            display: flex;
            gap: 8px;
            align-items: center;
        }
    }
    .ms-TextField-fieldGroup,
    .ms-ComboBox {
        height: 32px;
        font: normal normal normal 12px/15px Lato;
        letter-spacing: 0.36px;
        color: #707070 !important;
        width: 100% !important;
        border: 1px solid #cccbcb;
        padding-left: 0 !important;
        &:after {
            display: none !important;
        }
        &:focus,
        &:focus-within {
            border: 1px solid #b88e00 !important;
        }
    }
    input {
        padding: 8px 15px;
    }
    input: -internal-autofill-selected {
        background-color: #fff !important;
    }
    .ms-TextField-errorMessage {
        padding-top: 5px;
        span {
            text-transform: capitalize;
            color: #ce4e5b;
            font: normal normal normal 12px/14px Lato;
        }
    }
`;

export const PortalUserDetails: React.FC = observer(() => {
    const accountStore = container.resolve(AccountStore);
    const [viewModel] = useState(() => new PortalUserDetailsViewModel());
    const [newUser, setNewUser] = useState(false);
    const isMobile = useMediaQuery("(max-width: 767px)");

    let params = useParams();

    let id = params.id || "new";

    useEffect(() => {
        if (!accountStore.isInRole("admin") && !accountStore.isInRole("sales admin")) {
            viewModel.history.push("/");
        }
        if (id && id === "new") {
            setNewUser(true);
        } else {
            let _ = viewModel.loadUserAsync(id);
        }
    }, []);

    const doSubmit = async (e: any) => {
        e.preventDefault();
    };

    const comboBoxStyles: any = {
        Container: { borderBottom: "1px solid #000" },
        optionsContainer: { top: "10px", maxHeight: "225px", paddingBottom: "5px" },
    };

    return (
        <>
            <Box background={"#325083"} padding={"25px 15px"}>
                <Container id={"rootcontainer"}>
                    <Label
                        fontStyle={"h1"}
                        mt={"0"}
                        mb={"6px"}
                        style={{
                            letterSpacing: "-0.24px",
                            color: "#FFFFFF",
                            textTransform: "uppercase",
                            opacity: 1,
                            fontSize: "24px",
                            lineHeight: "30px",
                            fontWeight: "normal",
                        }}
                    >
                        {newUser ? "PORTAL - NEW PORTAL USERS" : `PORTAL - ${viewModel.firstName} ${viewModel.lastName}`}
                    </Label>
                </Container>
            </Box>
            <Box padding={"35px 15px"}>
                <Container id={"rootcontainer"}>
                    <UserForm>
                        <Box
                            flexBox
                            mb={"22px"}
                            alignItems={"center"}
                            width={"100%"}
                            height={"30px"}
                            onClick={() => viewModel.cancelSaveUserCommand.execute()}
                            style={{
                                cursor: "pointer",
                                gap: "8px",
                                letterSpacing: "-0.18px",
                                color: "#325083",
                                textTransform: "uppercase",
                                font: "normal normal 300 18px/30px Lato",
                            }}
                        >
                            <BackPageSVG />
                            BACK TO PORTAL USERS
                        </Box>
                        <Box display={"flex"} alignItems="center" flexWrap={"wrap"} justifyContent="space-between" mb={"32px"} style={{ gap: "12px 0" }}>
                            <Label mt={"0"} fontStyle={"h3"} style={{ font: "normal normal bold 18px/24px Lato", letterSpacing: "-0.31px", color: "#4A4A4A" }}>
                                {newUser ? "New User" : `${viewModel.firstName} ${viewModel.lastName}`}
                            </Label>
                        </Box>

                        <Box style={{ borderBottom: "1px solid #bebebe" }}>
                            <form onSubmit={doSubmit} id="detailsPanelForm" style={{ borderBottom: "1px dashed #bebebe", paddingBottom: "30px" }}>
                                <DetailContainer>
                                    <AutoGrid desktopColumns={"1fr 1fr 1fr 1fr"} rowGap={"22px"} columnGap={"30px"} marginBottom={"26px"}>
                                        <ThemedEditText
                                            displayName="First Name"
                                            value={() => viewModel.getValue("firstName")}
                                            command={viewModel.updateFirstNameCommand}
                                            validationMessage={() => viewModel.getError("firstName")}
                                            autoFill={false}
                                        />
                                        <ThemedEditText
                                            displayName="Last Name"
                                            value={() => viewModel.getValue("lastName")}
                                            command={viewModel.updateLastNameCommand}
                                            validationMessage={() => viewModel.getError("lastName")}
                                            autoFill={false}
                                        />
                                    </AutoGrid>
                                    <AutoGrid desktopColumns={"1fr 1fr 1fr 1fr"} rowGap={"22px"} columnGap={"30px"} marginBottom={"26px"}>
                                        <ThemedEditText
                                            displayName="Email Address"
                                            value={() => viewModel.getValue("emailAddress")}
                                            command={viewModel.updateEmailAddressCommand}
                                            validationMessage={() => viewModel.getError("emailAddress")}
                                            autoFill={false}
                                        />
                                    </AutoGrid>
                                </DetailContainer>
                            </form>

                            <AutoGrid dc={"1fr 1fr"} py={"30px"} rowGap={"22px"} columnGap={"30px"}>
                                <ThemedComboBox
                                    displayName={"Developments"}
                                    command={viewModel.updateDevelopmentIdCommand}
                                    options={viewModel.developmentOptions}
                                    value={() => viewModel.getValue("developmentId")!}
                                    styles={comboBoxStyles}
                                    validationMessage={() => viewModel.getError("developmentId")}
                                    placeholder={"Please select a development..."}
                                    canClearText={true}
                                />
                                <ThemedComboBox
                                    displayName={"Plots"}
                                    command={viewModel.updateCoinsPlotIdCommand}
                                    options={viewModel.plotOptions}
                                    value={() => viewModel.getValue("plotId")}
                                    validationMessage={() => viewModel.getError("plotId")}
                                    placeholder={"Please select a plot..."}
                                    styles={comboBoxStyles}
                                    canClearText={true}
                                />
                            </AutoGrid>
                        </Box>
                        <AutoGrid desktopColumns={"1fr 1fr 1fr 1fr"} rowGap={"22px"} columnGap={"30px"} mt={"30px"}>
                            {!newUser && (
                                <Box display={"flex"} justifyContent="flex-end">
                                    <Button
                                        paletteColor={"secondary"}
                                        type="submit"
                                        displayName={
                                            !viewModel.apiClient.IsBusy ? (
                                                <Box display={"flex"} justifyContent="center" alignItems={"center"} style={{ gap: "5px" }}>
                                                    <img src={arrow} className="img-fluid" alt="image" style={{ width: "19px" }} />
                                                    Issue New Password
                                                </Box>
                                            ) : (
                                                "Please wait..."
                                            )
                                        }
                                        command={viewModel.forgotPasswordCommand}
                                        style={{
                                            background: "#B88E00",
                                            maxWidth: isMobile ? "100%" : "280px",
                                            width: isMobile ? "100%" : "280px",
                                            borderRadius: 0,
                                            height: "30px",
                                            font: "normal normal 300 12px/15px Lato",
                                            letterSpacing: "0.36px",
                                            color: "#FFFFFF",
                                            textTransform: "uppercase",
                                            flex: isMobile ? "100% 0 0" : "280px 0 0",
                                        }}
                                    />
                                </Box>
                            )}
                            <Button
                                paletteColor={"secondary"}
                                type="submit"
                                displayName={id === "new" ? "Create" : !viewModel.apiClient.IsBusy ? "Save" : "Saving"}
                                command={viewModel.saveUserCommand}
                                style={{
                                    background: "#B88E00",
                                    maxWidth: "100%",
                                    width: "100%",
                                    borderRadius: 0,
                                    height: "30px",
                                    font: "normal normal 300 12px/15px Lato",
                                    letterSpacing: "0.36px",
                                    color: "#FFFFFF",
                                    textTransform: "uppercase",
                                }}
                            />
                            <Button
                                paletteColor={"error"}
                                displayName={"Cancel"}
                                command={viewModel.cancelSaveUserCommand}
                                style={{
                                    background: "#4A4A4A",
                                    maxWidth: "100%",
                                    width: "100%",
                                    borderRadius: 0,
                                    height: "30px",
                                    font: "normal normal 300 12px/14px Lato",
                                    letterSpacing: "0.36px",
                                    color: "#FFFFFF",
                                    textTransform: "uppercase",
                                }}
                            />
                        </AutoGrid>
                        <div>{viewModel.apiClient.IsBusy ? "" : ""}</div>
                        <div className="error">{viewModel.resetLoginAttemptsError}</div>
                    </UserForm>
                </Container>
            </Box>
        </>
    );
});
